import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypePostSurvey({ data, people }) {
    const [postSurvey, setPostSurvey] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPostPage, setCurrentPostPage] = useState({});
    const itemsPerPage = 5;
    const postItemsPerPage = 4;
    const { t, i18n } = useTranslation();
    const uniquePosts = [...new Set(people.flat().map(item => item.phone.work))].filter(callingnumber => {
        const callinglineid = data.find(item => item.callingnumber === callingnumber)?.callinglineid;
        return callinglineid;
    });

    useEffect(() => {
        handleGraphPostSurvey(data);
    }, [data]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphPostSurvey = (data) => {
        let postData = uniquePosts.map(post => ({
            post,
            callinglineid: "",
            Outgoing: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Incoming: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Total: {
                durationTotal: 0,
                responseTotal: 0
            },
            data: []
        }));

        data.forEach(item => {
            const post = uniquePosts.find(post => post === item.callingnumber);
            if (post) {
                const postIndex = postData.findIndex(p => p.post === post);
                postData[postIndex].data.push(item);

                const person = people.flat().find(person => person.phone.work === post);
                if (person) {
                    postData[postIndex].callinglineid = `${person.firstName} ${person.lastName}`;
                }

                if (item.direction === "TERMINATING") {
                    postData[postIndex].Outgoing.total += 1;
                    postData[postIndex].Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                    if (item.answerindicator === "Yes") {
                        postData[postIndex].Outgoing.response += 1;
                        postData[postIndex].Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                    } else if (item.answerindicator === "No") {
                        postData[postIndex].Outgoing.noresponse += 1;
                    }
                } else if (item.direction === "ORIGINATING") {
                    postData[postIndex].Incoming.total += 1;
                    postData[postIndex].Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                    if (item.answerindicator === "Yes") {
                        postData[postIndex].Incoming.response += 1;
                        postData[postIndex].Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                    } else if (item.answerindicator === "No") {
                        postData[postIndex].Incoming.noresponse += 1;
                    }
                }
            }
        });

        postData.forEach(post => {
            let Outgoing = post.Outgoing;
            let Incoming = post.Incoming;
            let Total = post.Total;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Total.responseTotal = Outgoing.response + Incoming.response;
            Total.durationTotal = formatDuration(Outgoing.durationTotal + Incoming.durationTotal);
            
            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
        });

        setPostSurvey(postData);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const paginatePost = (postIndex, pageNumber) => {
        setCurrentPostPage({ ...currentPostPage, [postIndex]: pageNumber });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    return (
        <div className="postSurvey">
            {postSurvey.length > 0 ? (
                <>
                    <div className="pagination-surveyPost">
                        <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            {t("Précédent")}
                        </button>
                        <button
                            onClick={() =>
                                paginate(
                                    currentPage < Math.ceil(postSurvey.length / itemsPerPage)
                                        ? currentPage + 1
                                        : currentPage
                                )
                            }
                            disabled={currentPage === Math.ceil(postSurvey.length / itemsPerPage)}
                        >
                            {t("Suivant")}
                        </button>
                    </div>
                    {postSurvey.slice(indexOfFirstItem, indexOfLastItem).map((post, postIndex) => (
                        <section className="postSurvey-element" key={post.post}>
                            <div>
                                <table className="postSurvey-sortants">
                                    <thead>
                                        <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                        <tr className="sortant-head">
                                            <th title="Poste">{t("Poste")}</th>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sortant-body">
                                            <td>{post.callinglineid}</td>
                                            <td>{post.Outgoing.response}</td>
                                            <td>{post.Outgoing.averageresponse}</td>
                                            <td>{post.Outgoing.noresponse}</td>
                                            <td>{post.Outgoing.durationTotal}</td>
                                            <td>{post.Outgoing.averageduration}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="postSurvey-entrants">
                                    <thead>
                                        <tr className="entrant"><th colSpan={5}>{t("Entrants")}</th></tr>
                                        <tr>
                                        <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                        <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                        <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                        <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                        <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{post.Incoming.response}</td>
                                            <td>{post.Incoming.averageresponse}</td>
                                            <td>{post.Incoming.noresponse}</td>
                                            <td>{post.Incoming.durationTotal}</td>
                                            <td>{post.Incoming.averageduration}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="postSurvey-total">
                                    <thead className="postSurvey-total-head">
                                        <tr className="total"><th colSpan={5}>{t("Total")}</th></tr>
                                        <tr>
                                            <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                            <th title="Nombre totale des appels répondu ">{t("Nombre totale des appels répondu")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="postSurvey-total-body">
                                        <tr>
                                            <td>{post.Total.durationTotal}</td>
                                            <td>{post.Total.responseTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <table className="postSurvey-post">
                                    <thead>
                                        <tr className="postSurvey-post-head">
                                            <th>{t("Date")}</th>
                                            <th>{t("Durée")}</th>
                                            <th>{t("Poste")}</th>
                                            <th>{t("Nom poste")}</th>
                                            <th>{t("Numéro")}</th>
                                            <th>{t("Nom")}</th>
                                            <th>{t("Origine")}</th>
                                            <th>{t("Résultat")}</th>
                                            <th>{t("Réponse")}</th>
                                            <th>{t("Type")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {post.data.slice((currentPostPage[post.post] || 1) * postItemsPerPage - postItemsPerPage,
                                            (currentPostPage[post.post] || 1) * postItemsPerPage).map((item, idx) => (
                                                <tr className="postSurvey-post-body" key={idx}>
                                                    <td>{item.starttime}</td>
                                                    <td>{item.duration}</td>
                                                    <td>{item.callingnumber}</td>
                                                    <td>{item.callinglineid}</td>
                                                    <td>{item.callednumber}</td>
                                                    <td>{item.calledlineid}</td>
                                                    <td>
                                                        {
                                                            item.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                                            item.calltype === 'SIP_NATIONAL' ? 'National' :
                                                            item.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                                            item.calltype === 'UNKNOWN' ? 'Inconnu' :
                                                            item.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                                            item.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                                            item.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                                            item.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                            item.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                            item.calltype === 'SIP_MEETING' ? 'Réunion' : 
                                                            item.calltype
                                                        }
                                                    </td>
                                                    <td>{item.answerindicator}</td>
                                                    <td>{item.ringduration}</td>
                                                    <td>{item.direction}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination-surveyPost-post">
                                <button
                                    onClick={() =>
                                        paginatePost(post.post, (currentPostPage[post.post] || 1) > 1 ? (currentPostPage[post.post] || 1) - 1 : (currentPostPage[post.post] || 1))
                                    }
                                    disabled={(currentPostPage[post.post] || 1) === 1}
                                >
                                    {t("Précédent")}
                                </button>
                                <button
                                    onClick={() =>
                                        paginatePost(post.post, (currentPostPage[post.post] || 1) < Math.ceil(post.data.length / postItemsPerPage) ? (currentPostPage[post.post] || 1) + 1 : (currentPostPage[post.post] || 1))
                                    }
                                    disabled={(currentPostPage[post.post] || 1) === Math.ceil(post.data.length / postItemsPerPage)}
                                >
                                    {t("Suivant")}
                                </button>
                            </div>
                        </section>
                    ))}
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
