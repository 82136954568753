import React, { useEffect, useState } from "react";
import ChartsWebex from "../../Charts/ChartsWebex";
import Loader from "../../../../../components/Loader";
import { StackedLineChart, StackedBarChart } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function TypeTrends({ data, people }) {
    const [typeTrends, setTypeTrends] = useState();
    const [dataTypeTrends, setDataTypeTrends] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPostPage, setCurrentPostPage] = useState({});
    const postItemsPerPage = 4;
    const [selectedTypeChart, setSelectedTypeChart] = useState('trends-bar');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (data.length > 0) {
            handleGraphTypeTrends(data);
        }
    }, [data]);

    useEffect(() => {
        if (typeTrends) {
            handleGraphDataTypeTrends(typeTrends.maxCallsData);
            setCurrentPage(1);
        }
    }, [typeTrends]);

    const handleGraphTypeTrends = (data) => {
        // Initialize the aggregated results with empty data for each hour
        const aggregatedResults = Array.from({ length: 24 }, (_, hour) => ({
            key: `${hour}:00`,
            date: "",
            Outgoing: { total: 0, durationTotal: 0, averageduration: 0, response: 0, averageresponse: 0, noresponse: 0 },
            Incoming: { total: 0, durationTotal: 0, averageduration: 0, response: 0, averageresponse: 0, noresponse: 0 },
            Total: { durationTotal: 0, responseTotal: 0 }
        }));

        // Prepare maxCallsData as in the original code (unchanged)
        const callCountsByDate = {};
        data.forEach(item => {
            const callDate = new Date(item.starttime).toLocaleDateString();
            callCountsByDate[callDate] = (callCountsByDate[callDate] || 0) + 1;
        });
        const maxCallsDate = Object.keys(callCountsByDate).reduce((maxDate, currentDate) =>
            callCountsByDate[currentDate] > callCountsByDate[maxDate] ? currentDate : maxDate
            , Object.keys(callCountsByDate)[0]);
        const maxCallsData = data.filter(item => new Date(item.starttime).toLocaleDateString() === maxCallsDate);

        // Populate aggregatedResults with data
        data.forEach(item => {
            const date = new Date(item.starttime);
            const hour = date.getHours();
            const dateString = date.toLocaleDateString();
            const duration = parseInt(item.duration) || 0;
            const ringDuration = parseInt(item.ringduration) || 0;
            const isResponse = item.answerindicator === "Yes";
            const direction = item.direction === "TERMINATING" ? "Outgoing" : "Incoming";

            if (!aggregatedResults[hour].date) {
                aggregatedResults[hour].date = dateString;
            }

            aggregatedResults[hour][direction].total += 1;
            aggregatedResults[hour][direction].durationTotal += duration;
            if (isResponse) {
                aggregatedResults[hour][direction].response += 1;
                aggregatedResults[hour][direction].averageresponse += ringDuration;
            } else {
                aggregatedResults[hour][direction].noresponse += 1;
            }
        });

        // Calculate average durations and responses
        aggregatedResults.forEach(hourData => {
            ['Outgoing', 'Incoming'].forEach(direction => {
                const data = hourData[direction];
                data.averageduration = data.total > 0 ? data.durationTotal / data.total : 0;
                data.averageresponse = data.response > 0 ? data.averageresponse / data.response : 0;
            });

            hourData.Total.durationTotal = hourData.Outgoing.durationTotal + hourData.Incoming.durationTotal;
            hourData.Total.responseTotal = hourData.Outgoing.response + hourData.Incoming.response;
            hourData.Outgoing.durationTotal = formatDuration(hourData.Outgoing.durationTotal);
            hourData.Outgoing.averageduration = formatDuration(hourData.Outgoing.averageduration);
            hourData.Incoming.durationTotal = formatDuration(hourData.Incoming.durationTotal);
            hourData.Incoming.averageduration = formatDuration(hourData.Incoming.averageduration);
            hourData.Outgoing.averageresponse = formatDuration(hourData.Outgoing.averageresponse);
            hourData.Incoming.averageresponse = formatDuration(hourData.Incoming.averageresponse);
        });

        setTypeTrends({
            maxCallsDate,
            maxCallsData,
            aggregatedResults,
        });
    };


    const handleGraphDataTypeTrends = (data) => {
        const uniquePosts = [...new Set(people.flat().map(item => item.phone.work))].filter(callingnumber => {
            const callinglineid = data.find(item => item.callingnumber === callingnumber)?.callinglineid;
            return callinglineid;
        });

        let postData = uniquePosts.map(post => ({
            post,
            callinglineid: "",
            Outgoing: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Incoming: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Total: {
                durationTotal: 0,
                responseTotal: 0
            },
            data: []
        }));

        data.forEach(item => {
            const post = uniquePosts.find(post => post === item.callingnumber);
            if (post) {
                const postIndex = postData.findIndex(p => p.post === post);
                postData[postIndex].data.push(item);

                const person = people.flat().find(person => person.phone.work === post);
                if (person) {
                    postData[postIndex].callinglineid = `${person.firstName} ${person.lastName}`;
                }

                if (item.direction === "TERMINATING") {
                    postData[postIndex].Outgoing.total += 1;
                    postData[postIndex].Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                    if (item.answerindicator === "Yes") {
                        postData[postIndex].Outgoing.response += 1;
                        postData[postIndex].Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                    } else if (item.answerindicator === "No") {
                        postData[postIndex].Outgoing.noresponse += 1;
                    }
                } else if (item.direction === "ORIGINATING") {
                    postData[postIndex].Incoming.total += 1;
                    postData[postIndex].Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                    if (item.answerindicator === "Yes") {
                        postData[postIndex].Incoming.response += 1;
                        postData[postIndex].Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                    } else if (item.answerindicator === "No") {
                        postData[postIndex].Incoming.noresponse += 1;
                    }
                }
            }
        });

        postData.forEach(post => {
            let Outgoing = post.Outgoing;
            let Incoming = post.Incoming;
            let Total = post.Total;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Total.responseTotal = Outgoing.response + Incoming.response;
            Total.durationTotal = Outgoing.durationTotal + Incoming.durationTotal;

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);

            Total.durationTotalFormatted = formatDuration(Total.durationTotal);
        });
        setDataTypeTrends(postData);
    }

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    let indexOfLastItem, indexOfFirstItem;
    if (currentPage === 1) {
        // Sur la première page, on n'affiche pas les postes
        indexOfFirstItem = 0;
        indexOfLastItem = 0;
    } else {
        // Sur les autres pages, afficher les postes
        indexOfLastItem = (currentPage - 2) * postItemsPerPage + postItemsPerPage; // Ajuster les index pour que la 2ème page commence au premier poste
        indexOfFirstItem = (currentPage - 2) * postItemsPerPage;
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const paginatePost = (postIndex, pageNumber) => {
        setCurrentPostPage({ ...currentPostPage, [postIndex]: pageNumber });
    };

    const handleTypeChartChange = (newTypeChart) => {
        setSelectedTypeChart(newTypeChart);
    };

    return (
        <div className="typeTrends">
            {typeTrends && dataTypeTrends ? (
                <section className="typeTrends-element">
                     <div className="pagination">
                        <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() =>
                                paginate(
                                    currentPage < Math.ceil(dataTypeTrends.length / postItemsPerPage) + 1
                                        ? currentPage + 1
                                        : currentPage
                                )
                            }
                            disabled={currentPage === Math.ceil(dataTypeTrends.length / postItemsPerPage) + 1}
                        >
                            Next
                        </button>
                    </div>
                    {currentPage === 1 ? (
                        <div className="typeTrends-element-charts">
                            <div className="page-chart-webex-total-content-buttons">
                                <div className="page-chart-webex-total-buttons">
                                    <button className={`${selectedTypeChart === 'trends-bar' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('trends-bar')}><StackedBarChart /></button>
                                    <button className={`${selectedTypeChart === 'trends-line' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('trends-line')}><StackedLineChart /></button>
                                </div>
                            </div>
                            {Object.values(
                                typeTrends.aggregatedResults.reduce((acc, item) => {
                                    acc[item.key] = item.Outgoing.response;
                                    return acc;
                                }, {})
                            ).some(value => value !== 0) && (
                                    <div>
                                        <ChartsWebex
                                            dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                                acc[item.key] = item.Outgoing.response;
                                                return acc;
                                            }, {})}
                                            allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                            allDates={Object.values(typeTrends.aggregatedResults).map(item => item.date)}
                                            typeOfChart={selectedTypeChart}
                                            titleChart={t("Sortant - Nombre d'appels répondus")}
                                        />
                                    </div>
                                )}
                            {Object.values(
                                typeTrends.aggregatedResults.reduce((acc, item) => {
                                    acc[item.key] = item.Outgoing.noresponse;
                                    return acc;
                                }, {})
                            ).some(value => value !== 0) && (
                                    <div>
                                        <ChartsWebex
                                            dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                                acc[item.key] = item.Outgoing.noresponse;
                                                return acc;
                                            }, {})}
                                            allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                            allDates={Object.values(typeTrends.aggregatedResults).map(item => item.date)}
                                            typeOfChart={selectedTypeChart}
                                            titleChart={t("Sortant - Nombre d'appels non répondus")}
                                        />
                                    </div>
                                )}
                            {Object.values(
                                typeTrends.aggregatedResults.reduce((acc, item) => {
                                    acc[item.key] = item.Incoming.response;
                                    return acc;
                                }, {})
                            ).some(value => value !== 0) && (
                                    <div>
                                        <ChartsWebex
                                            dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                                acc[item.key] = item.Incoming.response;
                                                return acc;
                                            }, {})}
                                            allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                            allDates={Object.values(typeTrends.aggregatedResults).map(item => item.date)}
                                            typeOfChart={selectedTypeChart}
                                            titleChart={t("Entrant - Nombre d'appels répondus")}
                                        />
                                    </div>
                                )}
                            {Object.values(
                                typeTrends.aggregatedResults.reduce((acc, item) => {
                                    acc[item.key] = item.Incoming.noresponse;
                                    return acc;
                                }, {})
                            ).some(value => value !== 0) && (
                                    <div>
                                        <ChartsWebex
                                            dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                                acc[item.key] = item.Incoming.noresponse;
                                                return acc;
                                            }, {})}
                                            allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                            allDates={Object.values(typeTrends.aggregatedResults).map(item => item.date)}
                                            typeOfChart={selectedTypeChart}
                                            titleChart={t("Entrant - Nombre d'appels non répondus")}
                                        />
                                    </div>
                                )}
                            {Object.values(
                                typeTrends.aggregatedResults.reduce((acc, item) => {
                                    acc[item.key] = item.Total.durationTotal;
                                    return acc;
                                }, {})
                            ).some(value => value !== 0) && (
                                    <div>
                                        <ChartsWebex
                                            dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                                acc[item.key] = item.Total.durationTotal;
                                                return acc;
                                            }, {})}
                                            allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                            allDates={Object.values(typeTrends.aggregatedResults).map(item => item.date)}
                                            typeOfChart={selectedTypeChart}
                                            titleChart={t("Total - Durée totale des appels")}
                                            mesurementUnit={'s'}
                                        />
                                    </div>
                                )}
                            {Object.values(
                                typeTrends.aggregatedResults.reduce((acc, item) => {
                                    acc[item.key] = item.Total.responseTotal;
                                    return acc;
                                }, {})
                            ).some(value => value !== 0) && (
                                    <div>
                                        <ChartsWebex
                                            dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                                acc[item.key] = item.Total.responseTotal;
                                                return acc;
                                            }, {})}
                                            allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                            allDates={Object.values(typeTrends.aggregatedResults).map(item => item.date)}
                                            typeOfChart={selectedTypeChart}
                                            titleChart={t('Total - Réponse totale des appels')}
                                        />
                                    </div>
                                )}
                        </div>
                    ) : (
                        <>
                            <div className="typeTrends-element-firstData">
                                <table className="typeTrends-sortants">
                                    <thead>
                                        <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                        <tr className="sortant-head">
                                            <th>{t("Date")}</th>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeTrends.aggregatedResults.map((hourData, index) => (
                                            (hourData.date && (
                                                <tr className="sortant-body" key={index}>
                                                    <td>{hourData.key} {hourData.date}</td>
                                                    <td>{hourData.Outgoing.response}</td>
                                                    <td>{hourData.Outgoing.averageresponse}</td>
                                                    <td>{hourData.Outgoing.noresponse}</td>
                                                    <td>{hourData.Outgoing.durationTotal}</td>
                                                    <td>{hourData.Outgoing.averageduration}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                                <table className="typeTrends-entrants">
                                    <thead>
                                        <tr className="entrant"><th colSpan={6}>{t("Entrants")}</th></tr>
                                        <tr>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeTrends.aggregatedResults.map((hourData, index) => (
                                            (hourData.date && (
                                                <tr key={index}>
                                                    <td>{hourData.Incoming.response}</td>
                                                    <td>{hourData.Incoming.averageresponse}</td>
                                                    <td>{hourData.Incoming.noresponse}</td>
                                                    <td>{hourData.Incoming.durationTotal}</td>
                                                    <td>{hourData.Incoming.averageduration}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                                <table className="typeTrends-total">
                                    <thead>
                                        <tr className="total"><th colSpan={6}>{t("Total")}</th></tr>
                                        <tr className="typeTrends-total-head">
                                            <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                            <th title="Nombre totale des appels répondu ">{t("Nombre totale des appels répondu")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeTrends.aggregatedResults.map((hourData, index) => (
                                            (hourData.date && (
                                                <tr className="typeTrends-total-body" key={index}>
                                                    <td>{formatDuration(hourData.Total.durationTotal)}</td>
                                                    <td>{hourData.Total.responseTotal}</td>
                                                </tr>
                                            ))
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {dataTypeTrends.slice(indexOfFirstItem, indexOfLastItem).map((post) => (
                                <section className="typeTrends-element" key={post.post}>
                                    <div>
                                        <table className="typeTrends-sortants">
                                            <thead>
                                                <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                                <tr className="sortant-head">
                                                    <th>{t("Poste")}</th>
                                                    <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                                    <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                                    <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                                    <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                                    <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="sortant-body">
                                                    <td>{post.callinglineid}</td>
                                                    <td>{post.Outgoing.response}</td>
                                                    <td>{post.Outgoing.averageresponse}</td>
                                                    <td>{post.Outgoing.noresponse}</td>
                                                    <td>{post.Outgoing.durationTotal}</td>
                                                    <td>{post.Outgoing.averageduration}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="typeTrends-entrants">
                                            <thead>
                                                <tr className="entrant"><th colSpan={6}>{t("Entrants")}</th></tr>
                                                <tr>
                                                    <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                                    <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                                    <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                                    <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                                    <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{post.Incoming.response}</td>
                                                    <td>{post.Incoming.averageresponse}</td>
                                                    <td>{post.Incoming.noresponse}</td>
                                                    <td>{post.Incoming.durationTotal}</td>
                                                    <td>{post.Incoming.averageduration}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="typeTrends-total">
                                            <thead>
                                                <tr className="total"><th colSpan={5}>{t("Total")}</th></tr>
                                                <tr className="typeTrends-total-head">
                                                    <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                                    <th title="Nombre totale des appels répondu ">{t("Nombre totale des appels répondu")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="typeTrends-total-body">
                                                    <td>{post.Total.durationTotalFormatted}</td>
                                                    <td>{post.Total.responseTotal}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <table className="typeTrends-post">
                                            <thead>
                                                <tr className="typeTrends-post-head">
                                                    <th>{t("Date")}</th>
                                                    <th>{t("Durée")}</th>
                                                    <th>{t("Poste")}</th>
                                                    <th>{t("Nom poste")}</th>
                                                    <th>{t("Numéro")}</th>
                                                    <th>{t("Nom")}</th>
                                                    <th>{t("Origine")}</th>
                                                    <th>{t("Résultat")}</th>
                                                    <th>{t("Réponse")}</th>
                                                    <th>{t("Type")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {post.data.slice((currentPostPage[post.post] || 1) * postItemsPerPage - postItemsPerPage,
                                                    (currentPostPage[post.post] || 1) * postItemsPerPage).map((item, idx) => (
                                                        <tr className="typeTrends-post-body" key={idx}>
                                                            <td>{item.starttime}</td>
                                                            <td>{item.duration}</td>
                                                            <td>{item.callingnumber}</td>
                                                            <td>{item.callinglineid}</td>
                                                            <td>{item.callednumber}</td>
                                                            <td>{item.calledlineid}</td>
                                                            <td>
                                                                {
                                                                    item.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                                                        item.calltype === 'SIP_NATIONAL' ? 'National' :
                                                                            item.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                                                                item.calltype === 'UNKNOWN' ? 'Inconnu' :
                                                                                    item.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                                                                        item.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                                                                            item.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                                                                                item.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                                                                    item.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                                                                        item.calltype === 'SIP_MEETING' ? 'Réunion' :
                                                                                                            item.calltype
                                                                }
                                                            </td>
                                                            <td>{item.answerindicator}</td>
                                                            <td>{item.ringduration}</td>
                                                            <td>{item.direction}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination">
                                        <button
                                            onClick={() =>
                                                paginatePost(post.post, (currentPostPage[post.post] || 1) > 1 ? (currentPostPage[post.post] || 1) - 1 : (currentPostPage[post.post] || 1))
                                            }
                                            disabled={(currentPostPage[post.post] || 1) === 1}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() =>
                                                paginatePost(post.post,
                                                    (currentPostPage[post.post] || 1) <
                                                        Math.ceil(post.data.length / postItemsPerPage)
                                                        ? (currentPostPage[post.post] || 1) + 1
                                                        : (currentPostPage[post.post] || 1))
                                            }
                                            disabled={
                                                (currentPostPage[post.post] || 1) ===
                                                Math.ceil(post.data.length / postItemsPerPage)
                                            }
                                        >
                                            Next
                                        </button>
                                    </div>
                                </section>
                            ))}</>
                    )}
                </section>

            ) : (
                <Loader />
            )}
        </div>
    )
}