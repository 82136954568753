import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const TreeChart = ({ data }) => {
    const svgRef = useRef();
    const [svgHeight, setSvgHeight] = useState(600);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const width = 1200;
        const margin = { top: 30, right: 0, bottom: 0, left: 40 };

        const innerWidth = width - margin.left - margin.right;

        const root = d3.hierarchy(data, d => d.children);
        const treeLayout = d3.tree().size([innerWidth, svgHeight - margin.top - margin.bottom]);
        treeLayout(root);

        svg.selectAll("*").remove();

        const g = svg.append('g')
                     .attr('transform', `translate(${margin.left},${margin.top})`);

        // Define the tooltip
        const tooltip = d3.select("body").append("div")
            .style("position", "absolute")
            .style("background", "#fff")
            .style("border", "1px solid #ccc")
            .style("padding", "10px")
            .style("border-radius", "5px")
            .style("box-shadow", "0px 0px 2px rgba(0, 0, 0, 0.2)")
            .style("pointer-events", "none")
            .style("opacity", 0);

        // Draw links
        root.descendants().forEach((node) => {
            const calls = node.data.calls;

            if (node.parent) {
                const parentCalls = node.parent.data.calls;

                parentCalls.forEach((parentCall, parentIndex) => {
                    calls.forEach((childCall, childIndex) => {
                        const lineColor = childCall.answerindicator === 'Yes' || childCall.answerindicator === 'Yes-PostRedirection'  ? '#AEC353' : '#aaa';

                        g.append('path')
                            .attr('d', d3.line()
                                .x(d => d[0])
                                .y(d => d[1])
                                .curve(d3.curveBasis)([
                                    [node.parent.x, node.parent.y + parentIndex * 50],
                                    [(node.parent.x + node.x) / 2, node.parent.y + parentIndex * 50],
                                    [(node.parent.x + node.x) / 2, node.y + childIndex * 50],
                                    [node.x, node.y + childIndex * 50]
                                ]))
                            .style('stroke', lineColor)
                            .style('fill', 'none');
                    });
                });
            }
        });

        // Draw nodes and their connections
        root.descendants().forEach((node) => {
            const calls = node.data.calls;

            calls.forEach((call, index) => {
                // Define color based on answerindicator
                let fillColor;
                switch (call.answerindicator) {
                    case 'Yes':
                        fillColor = '#AEC353';
                        break;
                    case 'Yes-PostRedirection':
                        fillColor = '#997adb';
                        break;
                    default:
                        fillColor = '#ccc';
                }

                // Draw circles for each call
                g.append('circle')
                    .attr('cx', node.x)
                    .attr('cy', node.y + index * 50) // Increase the gap between circles for clarity
                    .attr('r', 5)
                    .style('fill', fillColor)
                    .on("mouseover", function(event) {
                        // Compute classes
                        const calloutOutcomeClass = call.calloutcome === 'Success' ? 'tooltip-webex-legs-success' :
                                                   call.calloutcome === 'Failure' ? 'tooltip-webex-legs-failure' :
                                                   'tooltip-webex-legs-refusal';
                        const answerIndicatorClass = call.answerindicator === 'Yes' ? 'tooltip-webex-legs-success' :
                                                     call.answerindicator === 'Yes-PostRedirection' ? 'tooltip-webex-legs-redirect' :
                                                     'tooltip-webex-legs-refusal';
                        const directionIndicatorClass = call.direction === 'ORIGINATING' ? 'tooltip-webex-legs-redirect' : 'tooltip-webex-legs-refusal';
                        // Set tooltip content
                        tooltip.transition().duration(200).style("opacity", 1);
                        tooltip.html(`
                            <p>Final Remote Id: <span> ${call.finalremotesessionid}</span></p>
                            <p>Local Session Id: <span> ${call.localcallid}</span></p>
                            <p>Realted Call Id: <span> ${call.relatedcallid}</span></p>
                            <p>Remote Call Id: <span> ${call.remotecallid}</span></p>
                            <p class="${directionIndicatorClass}">Direction: <span> ${call.direction}</span></p>
                            <p>Date de début: ${call.starttime || 'N/A'}</p>
                            <p>Localisation: ${call.location || 'N/A'}</p>
                            <p>Numéro d'appel: ${call.callednumber || 'N/A'}</p>
                            <p>Ligne appelé: ${call.calledlineid || 'N/A'}</p>
                            <p>Numéro appelé: ${call.callingnumber || 'N/A'}</p>
                            <p>Ligne appelante: ${call.callinglineid || 'N/A'}</p>
                            <p>Type d'appel: 
                                ${
                                    call.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                    call.calltype === 'SIP_NATIONAL' ? 'National' :
                                    call.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                    call.calltype === 'UNKNOWN' ? 'Inconnu' :
                                    call.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                    call.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                    call.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                    call.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                    call.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                    call.calltype === 'SIP_MEETING' ? 'Réunion' : call.calltype
                                    || 'NA'
                                }
                            </p>
                            <p>Durée: ${call.duration || 'N/A'} secondes</p>
                            ${call.redirectingnumber ? `<p>Redirecting Number: ${call.redirectingnumber}</p>` : ''}
                            <p class="${calloutOutcomeClass}">
                                Résultat de l'appel: 
                                <span> 
                                    ${
                                        call.calloutcome === "Success" ? "Succès" :
                                        call.calloutcome === "Refusal" ? "Refusé" :
                                        call.calloutcome === "Failure" ? "Échec" : 'NA'
                                    }
                                </span>
                            </p>
                            <p class="${answerIndicatorClass}">
                                Indicateur de réponse: 
                                <span> 
                                    ${
                                        call.answerindicator === "Yes" ? "Oui" :
                                        call.answerindicator === "No" ? "Non" : "Redirection-d'appel"
                                        || 'NA'
                                    }
                                </span>
                            </p>
                        `)
                        .style("left", (event.pageX + 10) + "px")
                        .style("top", (event.pageY + 10) + "px");
                    })
                    .on("mousemove", function(event) {
                        tooltip.style("left", (event.pageX + 10) + "px")
                               .style("top", (event.pageY + 10) + "px");
                    })
                    .on("mouseout", function() {
                        tooltip.transition().duration(200).style("opacity", 0);
                    });

                const textData = [
                    `${call.callednumber || 'N/A'}`,
                    `${call.calledlineid || 'N/A'}`,
                    `${call.starttime || 'N/A'}`
                ];

                textData.forEach((text, i) => {
                    g.append('text')
                        .attr('x', node.x + 10)
                        .attr('y', node.y + index * 50 + i * 15)
                        .attr('dy', '0.35em')
                        .text(text)
                        .style('font-size', '11px')
                        .style('fill', 'black')
                        .attr('text-anchor', 'start');
                });
            });
        });

        // Function to update SVG height based on <g> bounding box
        const updateSvgHeight = () => {
            const bbox = g.node().getBBox();
            setSvgHeight(bbox.height + 30); // Add extra space for margin/padding
        };

        // Update SVG height after drawing elements
        updateSvgHeight();

        // Clean up the tooltip on component unmount
        return () => {
            tooltip.remove();
        };
    }, [data]);

    return <svg ref={svgRef} width="100%" height={svgHeight}></svg>;
};

export default TreeChart;