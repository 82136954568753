import React from 'react';
import xlsx from 'node-xlsx';
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import { FileDownload } from "@mui/icons-material";

export default function FileSaverCSV({ data, name }) {
    const { t } = useTranslation();

    const handleConfirmImport = async () => {
        const blob = generateExcelData(data, name);
        saveAs(blob, "data.xlsx");
    }

    const generateExcelData = (devices, fileName) => {
        //Avoir toute les données du tableau
        const combinedData = [];
        devices.forEach(device => {
            Object.values(device).forEach(callDetail => {
                combinedData.push(callDetail);
            });
        });
        // Extract only the "0" element from each device object
        // const extractedData = devices.map(device => device["0"]);
    
        // Transform data for Excel export (assuming some excel export logic here)
        const dataExcel = combinedData.map(callDetail => {
            const { source, ...data } = callDetail;
            return data;
        });

        const columnNames = Object.keys(dataExcel[0]);
        const excelData = [
            columnNames,
            ...dataExcel.map(device => {
                const rowData = columnNames.map(col => {
                    // Récupérer la valeur de la colonne ou la date si c'est une clé correspondante
                    const dateKeys = ["starttime", "endtime", "releasetime", "answertime", "reporttime"];
                    return dateKeys.includes(col) && device[col] ? device[col].date : device[col] || "";
                });
                return rowData;
            })
        ];
    
        // Créer le contenu du fichier Excel
        const excelBuffer = xlsx.build([{ name: fileName, data: excelData }]);
        // Convertir le contenu en Blob
        return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    };

    return (
        <div onClick={handleConfirmImport} className="saveCSVWebexCalling"><FileDownload/> {t("Save as CSV")}</div>
    )
}