import React, { useEffect, useState } from "react";
import ChartsWebex from "../../Charts/ChartsWebex";
import Loader from "../../../../../components/Loader";
import { StackedLineChart, StackedBarChart } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function TypeTrendsSite({ data, people }) {
    const [typeTrends, setTypeTrends] = useState(null);
    const [dataTypeTrends, setDataTypeTrends] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPostPage, setCurrentPostPage] = useState({});
    const postItemsPerPage = 4;
    const [selectedTypeChart, setSelectedTypeChart] = useState('trends-bar');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (data.length > 0) {
            handleGraphTypeTrends(data);
        }
    }, [data]);

    useEffect(() => {
        if (typeTrends) {
            handleGraphDataTypeTrends(typeTrends);
            setCurrentPage(1);
        }
    }, [typeTrends]);

    const handleGraphTypeTrends = (data) => {
        const callCountsBySiteAndDate = {};
        const uniqueDates = new Set();

        // Compter les appels par date pour chaque site
        data.forEach(item => {
            const callDate = new Date(item.starttime).toLocaleDateString();
            const site = item.location;

            uniqueDates.add(callDate);

            if (!callCountsBySiteAndDate[site]) {
                callCountsBySiteAndDate[site] = {};
            }

            if (!callCountsBySiteAndDate[site][callDate]) {
                callCountsBySiteAndDate[site][callDate] = {
                    Outgoing: {
                        total: 0,
                        durationTotal: 0,
                        averageduration: 0,
                        response: 0,
                        averageresponse: 0,
                        noresponse: 0
                    },
                    Incoming: {
                        total: 0,
                        durationTotal: 0,
                        averageduration: 0,
                        response: 0,
                        averageresponse: 0,
                        noresponse: 0
                    },
                    Total: {
                        responseTotal: 0,
                        durationTotal: 0
                    }
                };
            }

            const callData = callCountsBySiteAndDate[site][callDate];

            if (item.direction === "TERMINATING") {
                callData.Outgoing.total += 1;
                callData.Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    callData.Outgoing.response += 1;
                    callData.Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    callData.Outgoing.noresponse += 1;
                }
            } else if (item.direction === "ORIGINATING") {
                callData.Incoming.total += 1;
                callData.Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    callData.Incoming.response += 1;
                    callData.Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    callData.Incoming.noresponse += 1;
                }
            }
        });

        const isPerDays = uniqueDates.size <= 2;

        const maxCallsBySite = {};

        Object.keys(callCountsBySiteAndDate).forEach(site => {
            const siteDates = callCountsBySiteAndDate[site];
            const maxCallsDate = Object.keys(siteDates).reduce((maxDate, currentDate) => {
                const currentTotalCalls = siteDates[currentDate].Outgoing.total + siteDates[currentDate].Incoming.total;
                const maxTotalCalls = siteDates[maxDate] ? siteDates[maxDate].Outgoing.total + siteDates[maxDate].Incoming.total : 0;
                return currentTotalCalls > maxTotalCalls ? currentDate : maxDate;
            }, Object.keys(siteDates)[0]);

            const maxCallsData = data.filter(item => new Date(item.starttime).toLocaleDateString() === maxCallsDate && item.location === site);

            const Outgoing = siteDates[maxCallsDate].Outgoing;
            const Incoming = siteDates[maxCallsDate].Incoming;
            const Total = siteDates[maxCallsDate].Total;

            Total.responseTotal = Outgoing.response + Incoming.response;
            Total.durationTotal = Outgoing.durationTotal + Incoming.durationTotal;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Total.durationTotalFormatted = formatDuration(Total.durationTotal);

            maxCallsBySite[site] = {
                maxCallsDate,
                maxCallsData,
                totalCalls: Outgoing.total + Incoming.total,
                site: site,
                PerDays: isPerDays,
                Outgoing: {
                    ...Outgoing,
                    durationTotal: formatDuration(Outgoing.durationTotal),
                    averageduration: formatDuration(Outgoing.averageduration),
                    averageresponse: formatDuration(Outgoing.averageresponse)
                },
                Incoming: {
                    ...Incoming,
                    durationTotal: formatDuration(Incoming.durationTotal),
                    averageduration: formatDuration(Incoming.averageduration),
                    averageresponse: formatDuration(Incoming.averageresponse)
                },
                Total : {
                    ...Total
                }
            };
        });

        setTypeTrends(maxCallsBySite);
    };

    const handleGraphDataTypeTrends = (typeTrends) => {
        if (!typeTrends || typeof typeTrends !== 'object') {
            console.error("TypeTrends is undefined or not an object.");
            return;
        }

        // Get unique calling numbers (posts)
        const uniquePosts = [...new Set(people.flat().map(item => item.phone.work))];

        // Initialize postData array to hold statistics for each post
        let postData = uniquePosts.map(post => ({
            post,
            callinglineid: "",
            Outgoing: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Incoming: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Total: {
                durationTotal: 0,
                responseTotal: 0
            },
            data: []
        }));

        // Iterate through each site in typeTrends
        Object.keys(typeTrends).forEach(site => {
            const maxCallsData = typeTrends[site].maxCallsData;

            // Iterate through maxCallsData to aggregate statistics by post (callingnumber)
            maxCallsData.forEach(item => {
                const post = postData.find(p => p.post === item.callingnumber);
                if (post) {
                    post.data.push(item);

                    if (!post.callinglineid) {
                        const person = people.flat().find(person => person.phone.work === post.post);
                        post.callinglineid = person ? `${person.firstName} ${person.lastName}` : "";
                    }

                    if (item.direction === "TERMINATING") {
                        post.Outgoing.total += 1;
                        post.Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                        if (item.answerindicator === "Yes") {
                            post.Outgoing.response += 1;
                            post.Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                        } else if (item.answerindicator === "No") {
                            post.Outgoing.noresponse += 1;
                        }
                    } else if (item.direction === "ORIGINATING") {
                        post.Incoming.total += 1;
                        post.Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                        if (item.answerindicator === "Yes") {
                            post.Incoming.response += 1;
                            post.Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                        } else if (item.answerindicator === "No") {
                            post.Incoming.noresponse += 1;
                        }
                    }
                }
            });
        });

        postData = postData.filter(post => post.data.length > 0);

        // Calculate averages and totals for each post in postData
        postData.forEach(post => {
            let Outgoing = post.Outgoing;
            let Incoming = post.Incoming;
            let Total = post.Total;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Total.responseTotal = Outgoing.response + Incoming.response;
            Total.durationTotal = Outgoing.durationTotal + Incoming.durationTotal;

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);

            Total.durationTotalFormatted = formatDuration(Total.durationTotal);
        });

        // Update state with postData
        setDataTypeTrends(postData);
    };

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    let indexOfLastItem, indexOfFirstItem;
    if (currentPage === 1) {
        // Sur la première page, on n'affiche pas les postes
        indexOfFirstItem = 0;
        indexOfLastItem = 0;
    } else {
        // Sur les autres pages, afficher les postes
        indexOfLastItem = (currentPage - 2) * postItemsPerPage + postItemsPerPage; // Ajuster les index pour que la 2ème page commence au premier poste
        indexOfFirstItem = (currentPage - 2) * postItemsPerPage;
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const paginatePost = (postIndex, pageNumber) => {
        setCurrentPostPage({ ...currentPostPage, [postIndex]: pageNumber });
    };

    const handleTypeChartChange = (newTypeChart) => {
        setSelectedTypeChart(newTypeChart);
    };

    return (
        <div className="typeTrends">
            {typeTrends && dataTypeTrends ? (
                <section className="typeTrends-element">
                    <div className="pagination">
                    <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() =>
                                paginate(
                                    currentPage < Math.ceil(dataTypeTrends.length / postItemsPerPage) + 1
                                        ? currentPage + 1
                                        : currentPage
                                )
                            }
                            disabled={currentPage === Math.ceil(dataTypeTrends.length / postItemsPerPage) + 1}
                        >
                            Next
                        </button>
                    </div>
                    {currentPage === 1 ? (
                        <div className="typeTrends-element-charts">
                            <div className="page-chart-webex-total-content-buttons">
                                <div className="page-chart-webex-total-buttons">
                                    <button className={`${selectedTypeChart === 'trends-bar' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('trends-bar')}><StackedBarChart /></button>
                                    <button className={`${selectedTypeChart === 'trends-line' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('trends-line')}><StackedLineChart /></button>
                                </div>
                            </div>
                        {/* Sortant - Nombre d'appels répondus */}
                        {Object.values(
                            Object.keys(typeTrends).reduce((acc, location) => {
                                acc[typeTrends[location].site] = typeTrends[location].Outgoing.response;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={Object.keys(typeTrends).reduce((acc, location) => {
                                        acc[typeTrends[location].site] = typeTrends[location].Outgoing.response;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends).map(item => item.site)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Sortant - Nombre d'appels répondus")}
                                    labelTitle={Object.values(typeTrends).map(item => item.maxCallsDate)}
                                />
                            </div>
                        )}
                    
                        {/* Sortant - Nombre d'appels non répondus */}
                        {Object.values(
                            Object.keys(typeTrends).reduce((acc, location) => {
                                acc[typeTrends[location].site] = typeTrends[location].Outgoing.noresponse;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={Object.keys(typeTrends).reduce((acc, location) => {
                                        acc[typeTrends[location].site] = typeTrends[location].Outgoing.noresponse;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends).map(item => item.site)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Sortant - Nombre d'appels non répondus")}
                                    labelTitle={Object.values(typeTrends).map(item => item.maxCallsDate)}
                                />
                            </div>
                        )}
                    
                        {/* Entrant - Nombre d'appels répondus */}
                        {Object.values(
                            Object.keys(typeTrends).reduce((acc, location) => {
                                acc[typeTrends[location].site] = typeTrends[location].Incoming.response;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={Object.keys(typeTrends).reduce((acc, location) => {
                                        acc[typeTrends[location].site] = typeTrends[location].Incoming.response;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends).map(item => item.site)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Entrant - Nombre d'appels répondus")}
                                    labelTitle={Object.values(typeTrends).map(item => item.maxCallsDate)}
                                />
                            </div>
                        )}
                    
                        {/* Entrant - Nombre d'appels non répondus */}
                        {Object.values(
                            Object.keys(typeTrends).reduce((acc, location) => {
                                acc[typeTrends[location].site] = typeTrends[location].Incoming.noresponse;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={Object.keys(typeTrends).reduce((acc, location) => {
                                        acc[typeTrends[location].site] = typeTrends[location].Incoming.noresponse;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends).map(item => item.site)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Entrant - Nombre d'appels non répondus")}
                                    labelTitle={Object.values(typeTrends).map(item => item.maxCallsDate)}
                                />
                            </div>
                        )}
                    
                        {/* Total - Durée totale des appels */}
                        {Object.values(
                            Object.keys(typeTrends).reduce((acc, location) => {
                                acc[typeTrends[location].site] = typeTrends[location].Total.durationTotal;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={Object.keys(typeTrends).reduce((acc, location) => {
                                        acc[typeTrends[location].site] = typeTrends[location].Total.durationTotal;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends).map(item => item.site)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Total - Durée totale des appels")}
                                    labelTitle={Object.values(typeTrends).map(item => item.maxCallsDate)}
                                    mesurementUnit={'s'}
                                />
                            </div>
                        )}
                    
                        {/* Total - Réponse totale des appels */}
                        {Object.values(
                            Object.keys(typeTrends).reduce((acc, location) => {
                                acc[typeTrends[location].site] = typeTrends[location].Total.responseTotal;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={Object.keys(typeTrends).reduce((acc, location) => {
                                        acc[typeTrends[location].site] = typeTrends[location].Total.responseTotal;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends).map(item => item.site)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t('Total - Réponse totale des appels')}
                                    labelTitle={Object.values(typeTrends).map(item => item.maxCallsDate)}
                                />
                            </div>
                        )}
                    </div>                    
                    ) : (
                        <>
                            <div className="typeTrends-element-firstData">
                                <table className="typeTrends-sortants">
                                    <thead>
                                        <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                        <tr className="sortant-head">
                                            <th>{t("Date")}</th>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(typeTrends).map((site, siteIndex) => (
                                            <tr className="sortant-body" key={siteIndex}>
                                                <td>{typeTrends[site].maxCallsDate}</td>
                                                <td>{typeTrends[site].Outgoing.response}</td>
                                                <td>{typeTrends[site].Outgoing.averageresponse}</td>
                                                <td>{typeTrends[site].Outgoing.noresponse}</td>
                                                <td>{typeTrends[site].Outgoing.durationTotal}</td>
                                                <td>{typeTrends[site].Outgoing.averageduration}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="typeTrends-entrants">
                                    <thead>
                                        <tr className="entrant"><th colSpan={6}>{t("Entrants")}</th></tr>
                                        <tr>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(typeTrends).map((site, siteIndex) => (
                                            <tr key={siteIndex}>
                                                <td>{typeTrends[site].Incoming.response}</td>
                                                <td>{typeTrends[site].Incoming.averageresponse}</td>
                                                <td>{typeTrends[site].Incoming.noresponse}</td>
                                                <td>{typeTrends[site].Incoming.durationTotal}</td>
                                                <td>{typeTrends[site].Incoming.averageduration}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="typeTrends-total">
                                    <thead>
                                        <tr className="total"><th colSpan={6}>{t("Total")}</th></tr>
                                        <tr className="typeTrends-total-head">
                                            <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                            <th title="Nombre totale des appels répondu ">{t("Nombre totale des appels répondu")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(typeTrends).map((site, siteIndex) => (
                                            <tr key={siteIndex}>
                                                <td>{typeTrends[site].Total.durationTotalFormatted}</td>
                                                <td>{typeTrends[site].Total.responseTotal}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {dataTypeTrends.slice(indexOfFirstItem, indexOfLastItem).map((post) => (
                                <section className="typeTrends-element" key={post.post}>
                                    <div>
                                        <table className="typeTrends-sortants">
                                            <thead>
                                                <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                                <tr className="sortant-head">
                                                    <th>{t("Poste")}</th>
                                                    <th>{t("Appels rép...")}</th>
                                                    <th>{t("Durée moy pour rép...")}</th>
                                                    <th>{t("Appels non rép...")}</th>
                                                    <th>{t("Durée totale")}</th>
                                                    <th>{t("Durée moy.")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="sortant-body">
                                                    <td>{post.callinglineid}</td>
                                                    <td>{post.Outgoing.response}</td>
                                                    <td>{post.Outgoing.averageresponse}</td>
                                                    <td>{post.Outgoing.noresponse}</td>
                                                    <td>{post.Outgoing.durationTotal}</td>
                                                    <td>{post.Outgoing.averageduration}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="typeTrends-entrants">
                                            <thead>
                                                <tr className="entrant"><th colSpan={6}>{t("Entrants")}</th></tr>
                                                <tr>
                                                    <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                                    <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                                    <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                                    <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                                    <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{post.Incoming.response}</td>
                                                    <td>{post.Incoming.averageresponse}</td>
                                                    <td>{post.Incoming.noresponse}</td>
                                                    <td>{post.Incoming.durationTotal}</td>
                                                    <td>{post.Incoming.averageduration}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="typeTrends-total">
                                            <thead>
                                                <tr className="total"><th colSpan={5}>{t("Total")}</th></tr>
                                                <tr className="typeTrends-total-head">
                                                    <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                                    <th title="Nombre totale des appels répondu ">{t("Nombre totale des appels répondu")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{post.Total.durationTotalFormatted}</td>
                                                    <td>{post.Total.responseTotal}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <table className="typeTrends-post">
                                            <thead>
                                                <tr className="typeTrends-post-head">
                                                    <th>{t("Date")}</th>
                                                    <th>{t("Durée")}</th>
                                                    <th>{t("Poste")}</th>
                                                    <th>{t("Nom poste")}</th>
                                                    <th>{t("Numéro")}</th>
                                                    <th>{t("Nom")}</th>
                                                    <th>{t("Origine")}</th>
                                                    <th>{t("Résultat")}</th>
                                                    <th>{t("Réponse")}</th>
                                                    <th>{t("Type")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {post.data.slice((currentPostPage[post.post] || 1) * postItemsPerPage - postItemsPerPage,
                                                    (currentPostPage[post.post] || 1) * postItemsPerPage).map((item, idx) => (
                                                        <tr className="typeTrends-post-body" key={idx}>
                                                            <td>{item.starttime}</td>
                                                            <td>{item.duration}</td>
                                                            <td>{item.callingnumber}</td>
                                                            <td>{item.callinglineid}</td>
                                                            <td>{item.callednumber}</td>
                                                            <td>{item.calledlineid}</td>
                                                            <td>
                                                                {
                                                                    item.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                                                    item.calltype === 'SIP_NATIONAL' ? 'National' :
                                                                    item.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                                                    item.calltype === 'UNKNOWN' ? 'Inconnu' :
                                                                    item.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                                                    item.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                                                    item.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                                                    item.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                                    item.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                                    item.calltype === 'SIP_MEETING' ? 'Réunion' : 
                                                                    item.calltype
                                                                }
                                                            </td>
                                                            <td>{item.answerindicator}</td>
                                                            <td>{item.ringduration}</td>
                                                            <td>{item.direction}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination">
                                        <button
                                            onClick={() =>
                                                paginatePost(post.post, (currentPostPage[post.post] || 1) > 1 ? (currentPostPage[post.post] || 1) - 1 : (currentPostPage[post.post] || 1))
                                            }
                                            disabled={(currentPostPage[post.post] || 1) === 1}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() =>
                                                paginatePost(post.post,
                                                    (currentPostPage[post.post] || 1) <
                                                        Math.ceil(post.data.length / postItemsPerPage)
                                                        ? (currentPostPage[post.post] || 1) + 1
                                                        : (currentPostPage[post.post] || 1))
                                            }
                                            disabled={
                                                (currentPostPage[post.post] || 1) ===
                                                Math.ceil(post.data.length / postItemsPerPage)
                                            }
                                        >
                                            Next
                                        </button>
                                    </div>
                                </section>
                            ))}
                        </>
                    )}
                </section>
            ) : (
                <Loader/>
            )}
        </div>
    )
}