import React, { useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

export default function AccordionItem({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={toggleOpen}>
                <h2>{title}</h2>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </div>
            {isOpen && <div className="accordion-content">{children}</div>}
        </div>
    );
};