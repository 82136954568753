import React, { useState } from "react"
import { startOfDay, endOfDay, subDays, startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear } from 'date-fns';
import PopupFilteringReportWebex from "./PopupFilteringReportWebex";
import { useTranslation } from "react-i18next";

export default function FilteringReportWebex({ data, people, setFilteredData, reportType, setStatusReport, setAnalyseType, showPostPopup, setShowPostPopup, setShowEmailPopup }) {
    const today = endOfDay(new Date());
    const [filters, setFilters] = useState({
        typeTraffic: false,
        date: false,
        post: false,
        callType: false,
        destination: false,
        maxCall: false,
        analyse: false,
    });
    const [filterValues, setFilterValues] = useState({
        typeTraffic: 'all',
        date: '',
        post: [],
        callType: '',
        destination: '',
        maxCall: '',
        analyse: ''
    });

    const { t, i18n } = useTranslation();

    const handleFilterChange = (filter) => {
        setFilters((prev) => ({ ...prev, [filter]: !prev[filter] }));
    };

    const handleValueChange = (filter, value) => {
        setFilterValues((prev) => ({ ...prev, [filter]: value }));
    };

    const handlePostChange = (callingnumber) => {
        setFilterValues((prev) => {
            if (prev.post.includes(callingnumber)) {
                return { ...prev, post: prev.post.filter(post => post !== callingnumber) };
            } else {
                return { ...prev, post: [...prev.post, callingnumber] };
            }
        });
    };

    const applyFilters = () => {
        let flattenedData = data.flat();
        let filteredData = flattenedData;

        if (filters.typeTraffic && filterValues.typeTraffic !== 'Toutes') {
            filteredData = filteredData.filter(item =>
                filterValues.typeTraffic === 'Internes'
                    ? item.callinglineid !== 'NA'
                    : item.callinglineid === 'NA'
            );
        }

        if (filters.date) {
            filteredData = filteredData.filter(item => {
                const callDate = new Date(item.starttime);
                switch (filterValues.date) {
                    case 'today':
                        return callDate >= startOfDay(today) && callDate <= endOfDay(today);
                    case 'yesterday':
                        return callDate >= startOfDay(subDays(today, 1)) && callDate <= endOfDay(subDays(today, 1));
                    case 'last2days':
                        return callDate >= startOfDay(subDays(today, 2)) && callDate <= endOfDay(today);
                    case 'thisweek':
                        return callDate >= startOfDay(subDays(today, 7)) && callDate <= endOfDay(today);
                    case 'last7days':
                        return callDate >= startOfDay(subDays(today, 7)) && callDate <= endOfDay(today);
                    case 'lastweek':
                        return callDate >= startOfDay(subDays(today, 14)) && callDate <= endOfDay(subDays(today, 7));
                    case 'thismonth':
                        return callDate >= startOfMonth(today) && callDate <= endOfMonth(today);
                    case 'last30days':
                        return callDate >= startOfDay(subDays(today, 30)) && callDate <= endOfDay(today);
                    case 'lastmonth':
                        return callDate >= startOfMonth(subMonths(today, 1)) && callDate <= endOfMonth(subMonths(today, 1));
                    case 'thelast2months':
                        return callDate >= startOfMonth(subMonths(today, 2)) && callDate <= endOfMonth(subMonths(today, 1));
                    case 'lasttrimestre':
                        return callDate >= startOfDay(subDays(today, 90)) && callDate <= endOfDay(today);
                    case 'last60days':
                        return callDate >= startOfDay(subDays(today, 60)) && callDate <= endOfDay(today);
                    case 'last3months':
                        return callDate >= startOfDay(subDays(today, 90)) && callDate <= endOfDay(today);
                    case 'thisyear':
                        return callDate >= startOfYear(today) && callDate <= endOfYear(today);
                    case 'last12months':
                        return callDate >= startOfDay(subDays(today, 365)) && callDate <= endOfDay(today);
                    case 'lastyear':
                        return callDate >= startOfYear(subMonths(today, 12)) && callDate <= endOfYear(subMonths(today, 12));
                    case 'Last2years':
                        return callDate >= startOfYear(subMonths(today, 24)) && callDate <= endOfYear(subMonths(today, 12));
                    default:
                        return true;
                }
            });
        }

        if (filters.post && filterValues.post.length > 0) {
            filteredData = filteredData.filter(item =>
                filterValues.post.includes(item.callingnumber),
            );
        }

        if (filters.callType) {
            filteredData = filteredData.filter(item => {
                if (filterValues.callType === 'Sortants') {
                    return item.direction === "TERMINATING";
                } else if (filterValues.callType === 'Entrants') {
                    return item.direction === "ORIGINATING";
                } else if (filterValues.callType === 'Répondus') {
                    return item.answerindicator === "Yes";
                } else if (filterValues.callType === 'Sans réponse') {
                    return item.answerindicator === "No" || item.answerindicator === "Yes-PostRedirection";
                } else if (filterValues.callType === 'Appels sortants sans réponse') {
                    return item.direction === "TERMINATING" && (item.answerindicator === "No" || item.answerindicator === "Yes-PostRedirection");
                } else if (filterValues.callType === 'Appels sortants avec réponse') {
                    return item.direction === "TERMINATING" && item.answerindicator === "Yes";
                } else if (filterValues.callType === "Appels entrants sans réponse") {
                    return item.direction === "ORIGINATING" && (item.answerindicator === "No" || item.answerindicator === "Yes-PostRedirection");
                } else if (filterValues.callType === "Appels entrants avec réponse") {
                    return item.direction === "ORIGINATING" && item.answerindicator === "Yes";
                }
                return true;
            });
        }

        if (filters.destination && filterValues.destination !== '') {
            filteredData = filteredData.filter(item => {
                if (filterValues.destination === 'Interne') {
                    return item.calltype === 'SIP_ENTERPRISE';
                } else if (filterValues.destination === 'International') {
                    return item.calltype === 'SIP_INTERNATIONAL';
                } else if (filterValues.destination === 'National') {
                    return item.calltype === 'SIP_NATIONAL';
                } else if (filterValues.destination === 'Portable') {
                    return item.calltype === 'SIP_MOBILE';
                }
            });
        }

        if (filters.maxCall && filterValues.maxCall !== '' && Number.isInteger(Number(filterValues.maxCall))) {
            filteredData = filteredData.slice(0, parseInt(filterValues.maxCall, 10));
        }
        setFilteredData(filteredData);
        setAnalyseType(filterValues.analyse);
        setStatusReport("success");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        applyFilters();
    };

    const handlePostSubmit = (selectedPosts) => {
        setFilterValues((prev) => ({ ...prev, post: selectedPosts }));
        setShowPostPopup(false);
    };

    const handlePostCancel = () => {
        setShowPostPopup(false);
    };

    return (
        <section className="statistics-report-content">
            <div>
                <h1>
                    {t(`${reportType}`) === "Analyse temporelle (HH, JJ...)" ? "Analyse temporelle (heures, jours, mois ou trimestres)" : t(`${reportType}`)}
                </h1>
                <div className="filtering-options-report-content">
                    <h2 className="title-option-report-webex-filter">{t("Options du rapport :")}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="filter-report-webex">
                            <input
                                type="checkbox"
                                checked={filters.typeTraffic}
                                onChange={() => handleFilterChange('typeTraffic')}
                            />
                            <span>{t("Type de trafic :")}</span>
                            <select
                                value={filterValues.typeTraffic}
                                onChange={(e) => handleValueChange('typeTraffic', e.target.value)}
                                disabled={!filters.typeTraffic}
                            >
                                <option value=""></option>
                                <option value="Toutes">{t("Toutes")}</option>
                                <option value="Externes">{t("Externes")}</option>
                                <option value="Internes">{t("Internes")}</option>
                            </select>
                        </div>
                        <div className="filter-report-webex">
                            <input
                                type="checkbox"
                                checked={filters.date}
                                onChange={() => handleFilterChange('date')}
                            />
                            <span>{t("Pour les appels avec date :")}</span>
                            <select
                                value={filterValues.date}
                                onChange={(e) => handleValueChange('date', e.target.value)}
                                disabled={!filters.date}
                            >
                                <option value=""></option>
                                <option value="today">{t("Aujourd'hui")}</option>
                                <option value="yesterday">{t("Hier")}</option>
                                <option value="last2days">{t("2 derniers jours")}</option>
                                <option value="thisweek">{t("Cette semaine")}</option>
                                <option value="last7days">{t("7 derniers jours")}</option>
                                <option value="lastweek">{t("La semaine dernière")}</option>
                                <option value="thismonth">{t("Ce mois-ci")}</option>
                                <option value="last30days">{t("30 derniers jours")}</option>
                                <option value="lastmonth">{t("Le mois dernier")}</option>
                                <option value="thelast2months">{t("Les deux derniers mois")}</option>
                                <option value="lasttrimestre">{t("Dernier trimestre")}</option>
                                <option value="last60days">{t("60 derniers jours")}</option>
                                <option value="last3months">{t("3 derniers mois")}</option>
                                <option value="thisyear">{t("Cette année")}</option>
                                <option value="last12months">{t("12 derniers mois")}</option>
                                <option value="lastyear">{t("L'année dernière")}</option>
                                <option value="Last2years">{t("Deux dernières années")}</option>
                            </select>
                        </div>
                        <div className="filter-report-webex">
                            <input
                                type="checkbox"
                                checked={filters.post}
                                onChange={() => handleFilterChange('post')}
                            />
                            <span>{t("Pour un groupe restreint de postes :")}</span>
                            <button className="button-openselected-post-webex" type="button" onClick={() => filters.post && setShowPostPopup(true) + setShowEmailPopup(false)}>...</button>
                            {showPostPopup && filters.post && (
                                <PopupFilteringReportWebex
                                    data={data}
                                    filterValues={filterValues}
                                    handlePostChange={handlePostChange}
                                    onSubmit={handlePostSubmit}
                                    onCancel={handlePostCancel}
                                    people={people}
                                />
                            )}
                        </div>
                        <div className="filter-report-webex">
                            <input
                                type="checkbox"
                                checked={filters.callType}
                                onChange={() => handleFilterChange('callType')}
                            />
                            <span>{t("Pour les appels de type :")}</span>
                            <select
                                value={filterValues.callType}
                                onChange={(e) => handleValueChange('callType', e.target.value)}
                                disabled={!filters.callType}
                            >
                                <option value=""></option>
                                <option value="Répondus">{t("Répondus")}</option>
                                <option value="Sans réponse">{t("Sans réponse")}</option>
                                <option value="Sortants">{t("Sortants")}</option>
                                <option value="Appels sortants sans réponse">{t("Appels sortants sans réponse")}</option>
                                <option value="Appels sortants avec réponse">{t("Appels sortants avec réponse")}</option>
                                <option value="Entrants">{t("Entrants")}</option>
                                <option value="Appels entrants sans réponse">{t("Appels entrants sans réponse")}</option>
                                <option value="Appels entrants avec réponse">{t("Appels entrants avec réponse")}</option>
                            </select>
                        </div>
                        <div className="filter-report-webex">
                            <input
                                type="checkbox"
                                checked={filters.destination}
                                onChange={() => handleFilterChange('destination')}
                            />
                            <span>{t("Type d'origine :")}</span>
                            <select
                                value={filterValues.destination}
                                onChange={(e) => handleValueChange('destination', e.target.value)}
                                disabled={!filters.destination}
                            >
                                <option value=""></option>
                                <option value="Interne">{t("Interne")}</option>
                                <option value="International">{t("International")}</option>
                                <option value="National">{t("National")}</option>
                                <option value="Portable">{t("Portable")}</option>
                            </select>
                        </div>
                        <div className="filter-report-webex">
                            <input
                                type="checkbox"
                                checked={filters.maxCall}
                                onChange={() => handleFilterChange('maxCall')}
                            />
                            <span>{t("Nombre maximum d'appels :")}</span>
                            <input
                                type="number"
                                value={filterValues.maxCall}
                                onChange={(e) => handleValueChange('maxCall', e.target.value)}
                                disabled={!filters.maxCall}
                            />
                        </div>
                        {(reportType === "Analyse temporelle (HH, JJ...)"
                            || reportType === "Analyse temporelle (HH, JJ...) par sites"
                            || reportType === "Analyse temporelle (HH, JJ...) par postes") && (
                                <div className="filter-report-webex">
                                    <span>{t("Analyse par :")}</span>
                                    <select
                                        value={filterValues.analyse}
                                        onChange={(e) => handleValueChange('analyse', e.target.value)}
                                    >
                                        <option value=""></option>
                                        <option value="Heures">{t("Heures")}</option>
                                        <option value="Jours de la semaine">{t("Jours de la semaine")}</option>
                                        <option value="Semaine">{t("Semaine")}</option>
                                        <option value="Trimestres">{t("Trimestres")}</option>
                                        <option value="Mois">{t("Mois")}</option>
                                    </select>
                                </div>
                            )}
                        <button className="submit-report-webex" type="submit">{t("Exécuter le rapport")}</button>
                    </form>
                </div>
            </div>
        </section>
    )
}
