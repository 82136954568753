import React, { useContext, useState, useEffect } from "react"
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import RowCertificats from "./RowCertificats";
import FileSaverCSV from "../../components/FileSaverCSV";
import Form from "../../components/Forms/Form";
import Loader from "../../components/Loader";
import { CertificateSchema } from "../../components/Forms/schema/YupSchema";
import { CertificatColumn } from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import NumberCard from "../../components/Card/NumberCard";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { MoreVert } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function Certificats() {
  const { apiUrl, user } = useContext(AuthContext);
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const certificatId = searchParams.get("certificatId");
  const [filteredData, setFilteredData] = useState([]);
  const { t } = useTranslation();

  // Request to get all certificats
  const getAllCertficats = async () => {
    const res = await axios(`${apiUrl}certificats/`, {
      method: "GET",
      withCredentials: true,
    })
    return res.data
  };

  const certificats = useQuery({
    queryKey: ["certificats"],
    queryFn: getAllCertficats
  });

  useEffect(() => {
    if (certificats.data !== undefined && !certificats.data.includes("No data")) {
      if (certificatId) {
        const filteredData = certificats.data.filter(certif => certif.id.toString() === certificatId);
        setFilteredData(filteredData);
      } else {
        setFilteredData(certificats.data);
      }
    }
  }, [certificats.data, certificatId]);

  // Content to create new certificate
  const formContentNew = [
    { id: "certificat", type: "text", title: "Certificat" },
    { id: "type", type: "text", title: "Type" },
    { id: "fonction", type: "text", title: "Fonction" },
    { id: "plaque", type: "text", title: "Plaque" },
    { id: "expiration", type: "date", title: "Expiration" },
  ];

  // Request to create new certificate
  const createNewCertificat = async (data) => {

    const res = await axios(`${apiUrl}certificats/new`, {
      method: "post",
      withCredentials: true,
      data: {
        holding: user.holding,
        entreprise: user.entreprise,
        ...data,
      },
    })
    return res.data
  };

  if (certificats.isFetched && certificats.data.length === 0) {
    return <article className="page">
      <h1 className="page-title">
        Aucun certificat
      </h1>
    </article>
  }

  return (
    <>
      {!certificats.isLoading ? (
        <>
          <article className="page">
            <h1 className="page-title">{t('Certificats')}</h1>
            <section className="page-section">
              <NumberCard title="Certificats" number={filteredData.length} logo={faCircleCheck} color="#AEC353" stylesIcons="logo-certificats" />
            </section>
            {certificatId && (
              <div className="page-title page-link-certificat">
                <Link to={"/timeline"} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
              </div>
            )}
            <div className="table-leftbtn">
              <div className="container-file-saver-csv">
                <div className='tooltip-csv-tableWebex'>
                  <MoreVert />
                  <span className='tooltip-csv-tableWebex-button'><FileSaverCSV data={[certificats.data]} name={'Certificats'} /></span>
                </div>
                <Form
                  formContent={formContentNew}
                  schema={CertificateSchema}
                  submitFunction={createNewCertificat}
                  title="Enregistrer un certificat"
                  action="new"
                />
              </div>
            </div>
            <TableComponent
              tableData={filteredData}
              tableColumns={CertificatColumn}
              rowComponent={RowCertificats}
            />
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
