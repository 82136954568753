import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Submenu from "./Submenu";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";



export default function Categorie({
  category,
  activeBtn,
  index,
  secondDrop,
  handleSecondDrop,
  thirdDrop,
  handleThirdDrop,
  sideOpen
}) {

  // import context
  const { entrepriseRoles } = useContext(AuthContext);
  const {t} = useTranslation();

  // role control
  const shouldDisplayMenu = category.role
    ? category.role.some((role) => entrepriseRoles.data.includes(role))
    : true;

  return (
    <>
      {shouldDisplayMenu && !category.dontDisplay && (
        <li
          className={`categorie-item ${
            secondDrop === `${activeBtn}${index}` ? "categorie-item_open" : ""
          }`}
        >
          {category.submenu ? (
            <a
              className="categorie-link"
              onClick={(id) => {
                handleSecondDrop(`${activeBtn}${index}`);
              }}
            >
              {" "}
              {t(category.title)}
              {category.icon && category.icon}{" "}
            </a>
          ) : category.path.includes("http") ? (
            <a
              href={category.path}
              target={"_blank"}
              className="categorie-link"
            >
              {t(category.title)}
            </a>
          ) : (
            <div className="categorie-link">
              <NavLink to={category.path}>{t(category.title)}</NavLink>
            </div>
          )}
          {category.submenu ? (
            <ul
              className={`submenu-list ${
                secondDrop === `${activeBtn}${index}` ? "submenu-list_open" : ""
              }`}
            >
              {" "}
              {category.submenu.map((submenu, index) => {
                return (
                  <Submenu
                    key={index}
                    submenu={submenu}
                    index={index}
                    secondDrop={secondDrop}
                    thirdDrop={thirdDrop}
                    handleThirdDrop={handleThirdDrop}
                  />
                );
              })}
            </ul>
          ) : null}
        </li>
      )}
    </>
  );
}
