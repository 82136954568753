import {
  PiCaretDown,
  PiScreencast,
  PiShareNetwork,
  PiShieldCheckeredDuotone,
  PiUsersThree,
} from "react-icons/pi";

const menuData = [
  {
    item: "Réseau",
    icon: <PiShareNetwork />,
    category: [
      {
        title: "Monitoring",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Auvik",
            path: "/auvik",
            role: ["ROLE_AUVIK"]
          },
          {
            title: "Meraki",
            path: "/meraki",
            role: ["ROLE_MERAKI"],
          },
          {
            title: "Smart Account",
            path: "/smart_account",
            role: ["ROLE_SMARTACCOUNT"],
          }
        ],
      },

      {
        title: "Actifs",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Equipements",
            path: "/equipements",
            role: ["ROLE_EQUIPEMENT"],
          },
          {
            title: "Certificats",
            path: "/certificats", 
            role: ["ROLE_CERTIFICATS"],
          },
        ],
      },
      {
        title: "Reporting",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Hardware",
            path: "/hardware",
            role: ["ROLE_ANALYTICS"],
          },
        ],
      },
    ],
  },
  {
    item: "Sécurité",
    icon: <PiShieldCheckeredDuotone />,
    category: [
      {
        title: "Monitoring",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "SOC",
            path: "https://a1t10elitha.securonix.net/Snypr/",
          },
          {
            title: "Secure Endpoint",
            path: "/secure_endpoint/events",
            role: ["ROLE_AMP"],
          },
          {
            title: "Umbrella",
            path: "/umbrella",
            role: ["ROLE_UMBRELLA"],
          },
        ],
      },

      {
        title: "Actifs",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Firewall",
            path: "/firewall",
            role: ["ROLE_PALO"],
          },
          {
            title: "Liste Secure Endpoint",
            path: "/secure_endpoint/equipement",
            role: ["ROLE_AMP"],
          },
          {
            title: "Licences Palo Alto",
            path: "/licences/paloalto",
            role: ["ROLE_PALO"],
          },
        ],
      },
    ],
  },
  {
    item: "Workplace",
    icon: <PiScreencast />,
    category: [
      {
        title: "Monitoring",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Webex",
            path: "/webex",
            role: ["ROLE_WEBEX"],
          },
          {
            title: "Microsoft",
            path: "/microsoft",
            role: ["ROLE_0365"],
          },
          {
            title: "Poly",
            path: "/poly",
            role: ["ROLE_POLY"],
          },
          {
            title: "IoT",
            path: "/IoT",
            role: ["ROLE_WEBEX", "ROLE_MERAKI"],
          },
          {
            title: "Statistics",
            path: "/statistics",
            role: ["ROLE_SUPERADMIN"],
          },
          { title: "Qos voix/vidéo" },
          { title: "Workplace" }
        ],
      },
      {
        title: "Actifs",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Visio",
            path: "/visio",
            role: ["ROLE_WEBEX", "ROLE_POLY"],
          },
        ],
      },
    ],
  },

  {
    item: "Services Managés",
    icon: <PiUsersThree />,
    category: [
      {
        title: "Tickets",
        path: "/tickets",
        role: ["ROLE_INCIDENT"],
      },
      {
        title: "Contrats Cisco",
        icon: <PiCaretDown />,
        submenu: [
          {
            title: "Smartnet",
            path: "/Smartnet",
          },
          {
            title: "Subscription",
            path: "/subscription",
          },
          {
            title: "Timeline",
            path: "/timeline",
            role: ["ROLE_SUPERADMIN"],
          }
        ],
      },
      {
        title: "Portail",
        path: "https://servicedesk.elit-technologies.fr",
      },
      {
        title: "Parking",
        path: "/parking",
        role: ["ROLE_PARKING"]
      },
      {
        title: "Documentation",
        path: "/files_manager",
      },
    ],
  },
];

export default menuData;
