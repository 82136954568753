import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypeGeneralSurvey({ data }) {
    const [generalSurvey, setGeneralSurvey] = useState();
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        handleGraphGeneralSurvey(data);
    }, [data]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphGeneralSurvey = (data) => {
        let Outgoing = {
            total: 0,
            durationTotal: 0,
            averageduration: 0,
            response: 0,
            averageresponse: 0,
        };
        let Incoming = {
            total: 0,
            durationTotal: 0,
            averageduration: 0,
            response: 0,
            averageresponse: 0,
            directcalls: 0,
            callsforwarded: 0,
            lostcalls: 0
        };
        data.filter(item => {
            if (item.direction === "TERMINATING") {
                Outgoing.total += 1;
                Outgoing.durationTotal += parseInt(item.duration) || 0;
                if (item.answerindicator === "Yes") {
                    Outgoing.response += 1;
                    Outgoing.averageresponse += parseInt(item.ringduration) || 0;
                }
            } else if (item.direction === "ORIGINATING") {
                Incoming.total += 1;
                Incoming.durationTotal += parseInt(item.duration) || 0;
                if (item.answerindicator === "Yes") {
                    Incoming.response += 1;
                    Incoming.averageresponse += parseInt(item.ringduration) || 0;
                }
                if (item.calloutcome === 'Success') {
                    if (item.transferrelatedcallid) {
                        Incoming.callsforwarded += 1;
                    } else {
                        Incoming.directcalls += 1;
                    }
                } else if (item.calloutcome === 'Failure') {
                    Incoming.lostcalls += 1;
                }
            }
        });

        Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
        Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

        Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
        Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

        Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
        Outgoing.averageduration = formatDuration(Outgoing.averageduration);
        Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

        Incoming.durationTotal = formatDuration(Incoming.durationTotal);
        Incoming.averageduration = formatDuration(Incoming.averageduration);
        Incoming.averageresponse = formatDuration(Incoming.averageresponse);

        setGeneralSurvey({ Outgoing, Incoming });
    }

    return (
        <div className="generalSurvey">
            {generalSurvey ? (
                <div>
                    <table className="generalSurvey-sortants">
                        <thead className="sortants-head">
                            <tr>
                                <th colSpan={2}>{t("Sortants")}</th>
                            </tr>
                        </thead>
                        <tbody className="sortants-body">
                            <tr>
                                <td title="Nombre d'appels">{t("Nombre d'appels")}</td>
                                <td>{generalSurvey.Outgoing.total}</td>
                            </tr>
                            <tr>
                                <td title="Durée totale d'appels">{t("Durée totale d'appels")}</td>
                                <td>{generalSurvey.Outgoing.durationTotal}</td>
                            </tr>
                            <tr>
                                <td title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</td>
                                <td>{generalSurvey.Outgoing.averageduration}</td>
                            </tr>
                            <tr>
                                <td title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</td>
                                <td>{generalSurvey.Outgoing.response}</td>
                            </tr>
                            <tr>
                                <td title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</td>
                                <td>{generalSurvey.Outgoing.averageresponse}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="generalSurvey-entrants">
                        <thead className="entrants">
                            <tr>
                                <th colSpan={2}>{t("Entrants")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td title="Nombre d'appels">{t("Nombre d'appels")}</td>
                                <td>{generalSurvey.Incoming.total}</td>
                            </tr>
                            <tr>
                                <td title="Durée totale d'appels">{t("Durée totale d'appels")}</td>
                                <td>{generalSurvey.Incoming.durationTotal}</td>
                            </tr>
                            <tr>
                                <td title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</td>
                                <td>{generalSurvey.Incoming.averageduration}</td>
                            </tr>
                            <tr>
                                <td  title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</td>
                                <td>{generalSurvey.Incoming.response}</td>
                            </tr>
                            <tr>
                                <td title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</td>
                                <td>{generalSurvey.Incoming.averageresponse}</td>
                            </tr>
                            <tr>
                                <td title="Nombre d'appels directs">{t("Nombre d'appels directs")}</td>
                                <td>{generalSurvey.Incoming.directcalls}</td>
                            </tr>
                            <tr>
                                <td title="Nombre d'appels transférés">{t("Nombre d'appels transférés")}</td>
                                <td>{generalSurvey.Incoming.callsforwarded}</td>
                            </tr>
                            <tr>
                                <td title="Nombre d'appels perdus">{t("Nombre d'appels perdus")}</td>
                                <td>{generalSurvey.Incoming.lostcalls}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    );
}
