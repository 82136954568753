import React, { useEffect, useState } from "react";
import { ClearRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function PopupFilteringReportWebex({ data, filterValues, handlePostChange, onSubmit, onCancel, people }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredPosts, setFilteredPosts] = useState([]);
    const { t, i18n } = useTranslation();

    const uniquePosts = [...new Set(people.flat().map(item => item.phone.work))].filter(callingnumber => {
        const callinglineid = data.flat().find(item => item.callingnumber === callingnumber)?.callinglineid;
        return callinglineid;
    });

    useEffect(() => {
        if (searchTerm.trim() === "") {
            setFilteredPosts(uniquePosts);
        } else {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            const filtered = uniquePosts.filter(callingnumber => {
                const person = people.flat().find(item => item.phone.work === callingnumber);
                const callinglineid = data.flat().find(item => item.callingnumber === callingnumber)?.callinglineid;
                return (
                    callingnumber.includes(searchTerm) ||
                    (callinglineid && callinglineid.includes(searchTerm)) ||
                    (person?.firstName && person.firstName.toLowerCase().includes(lowerCaseSearchTerm)) ||
                    (person?.lastName && person.lastName.toLowerCase().includes(lowerCaseSearchTerm))
                );
            });
            setFilteredPosts(filtered);
        }
        setCurrentPage(1);

    }, [searchTerm, data, people]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleResetSearch = () => {
        setSearchTerm("");
    };

    const handleOK = () => {
        onSubmit(filterValues.post);
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div className="popup-filtering-report-webex-statistics">
            <h2>{t("Sélection des valeurs")}</h2>
            <div className="container-search-bar-filtering-report-webex">
                <div>
                    {t("Filtrer par :")}
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button onClick={handleResetSearch}><ClearRounded /></button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("Numéro")}</th>
                        <th>{t("Nom")}</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPosts.map((callingnumber, index) => {
                        const person = people.flat().find(item => item.phone.work === callingnumber);
                        const firstName = person?.firstName || 'N/A';
                        const lastName = person?.lastName || 'N/A';
                        return (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={filterValues.post.includes(callingnumber)}
                                        onChange={() => handlePostChange(callingnumber)}
                                    />
                                </td>
                                <td>{callingnumber}</td>
                                <td>{firstName} {lastName}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {filteredPosts.length > 10 && (
                <div className="pagination">
                    {[...Array(Math.min(Math.ceil(filteredPosts.length / postsPerPage), 10)).keys()].map(number => (
                        <button key={number} onClick={() => paginate(number + 1)}>
                            {number + 1}
                        </button>
                    ))}
                </div>
            )}
            <div className="container-buttons-popup-filtering-report-webex">
                <button onClick={handleOK}>{t("OK")}</button>
                <button onClick={handleCancel}>{t("Annuler")}</button>
            </div>
        </div>
    );
};