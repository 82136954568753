import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader";
import { useTranslation } from "react-i18next";
export default function TypeDestinationSurvey({ data }) {
    const [destinationSurvey, setDestinationSurvey] = useState();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        handleGraphDestinationSurvey(data);
    }, [data]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphDestinationSurvey = (data) => {
        let destinations = {};
        data.filter(item => {
            const calltype = item.calltype;
            if (!destinations[calltype]) {
                destinations[calltype] = {
                    Outgoing: {
                        total: 0,
                        durationTotal: 0,
                        averageduration: 0,
                        response: 0,
                        averageresponse: 0,
                        noresponse: 0
                    },
                    Incoming: {
                        total: 0,
                        durationTotal: 0,
                        averageduration: 0,
                        response: 0,
                        averageresponse: 0,
                        noresponse: 0
                    }
                };
            }
            if (item.direction === "TERMINATING") {
                destinations[calltype].Outgoing.total += 1;
                destinations[calltype].Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    destinations[calltype].Outgoing.response += 1;
                    destinations[calltype].Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    destinations[calltype].Outgoing.noresponse += 1;
                }
            } else if (item.direction === "ORIGINATING") {
                destinations[calltype].Incoming.total += 1;
                destinations[calltype].Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    destinations[calltype].Incoming.response += 1;
                    destinations[calltype].Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    destinations[calltype].Incoming.noresponse += 1;
                }
            }
        });

        for (let calltype in destinations) {
            let Outgoing = destinations[calltype].Outgoing;
            let Incoming = destinations[calltype].Incoming;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
        }
        setDestinationSurvey(destinations);
    }
    return (
        <div className="destinationSurvey">
            {destinationSurvey ? (
                <div>
                    <table className="destinationSurvey-sortants">
                        <thead>
                            <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                            <tr className="sortant-head">
                                <th title="Origine">{t("Origine")}</th>
                                <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(destinationSurvey).map((calltype, index) => (
                            <tr className="sortant-body" key={index}>
                                <td>
                                    {
                                        calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                        calltype === 'SIP_NATIONAL' ? 'National' :
                                        calltype === 'SIP_MOBILE' ? 'Mobile' :
                                        calltype === 'UNKNOWN' ? 'Inconnu' :
                                        calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                        calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                        calltype === 'SIP_INBOUND' ? 'Entrant' :
                                        calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                        calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                        calltype === 'SIP_MEETING' ? 'Réunion' : 
                                        calltype
                                    }
                                </td>
                                <td>{destinationSurvey[calltype].Outgoing.response}</td>
                                <td>{destinationSurvey[calltype].Outgoing.averageresponse}</td>
                                <td>{destinationSurvey[calltype].Outgoing.noresponse}</td>
                                <td>{destinationSurvey[calltype].Outgoing.durationTotal}</td>
                                <td>{destinationSurvey[calltype].Outgoing.averageduration}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <table className="destinationSurvey-entrants">
                        <thead>
                            <tr className="entrant"><th colSpan={6}>{t("Entrants")}</th></tr>
                            <tr>
                                <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(destinationSurvey).map((calltype, index) => (
                            <tr key={index}>                            
                                <td>{destinationSurvey[calltype].Incoming.response}</td>
                                <td>{destinationSurvey[calltype].Incoming.averageresponse}</td>
                                <td>{destinationSurvey[calltype].Incoming.noresponse}</td>
                                <td>{destinationSurvey[calltype].Incoming.durationTotal}</td>                             
                                <td>{destinationSurvey[calltype].Incoming.averageduration}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    );
}