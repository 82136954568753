import React from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import "./styles/main.scss";
import axios from "axios";
import { AuthContext } from "./context/AuthContext";
import Loader from "./components/Loader";
import { useQuery } from "@tanstack/react-query";
import { IconContext } from "react-icons/lib";
import RouterEcp from "./router/RouterEcp.jsx";
import Login from "./components/Forms/Login.jsx";
import { useCookies } from 'react-cookie';

export default function App() {
  const apiUrl = import.meta.env.VITE_API_URL;
  const [cookies] = useCookies(['isLogin']);
  const isLogin = cookies['isLogin'];

  const getUserInfo = () => {
    return axios({
      method: "get",
      url: `${apiUrl}api/user`,
      withCredentials: true,
    })
    .then((res) => res.data);
  };
  
  const { isLoading, data: user, isError, refetch } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserInfo(),
    enabled: isLogin ? true : false,
  });

  const getRoles = async () => {
    const res = await axios.get(`${apiUrl}menu`, {
      withCredentials: true,
    });
    return res.data[0].roles;
  };

  const entrepriseRoles = useQuery({
    queryKey: ["entrepriseRoles"],
    queryFn: getRoles,
    enabled: user ? true : false,
  });

  if (isError || !isLogin) {
    // console.clear();
    return (
        <AuthContext.Provider value={{ user, apiUrl, refetch }}>
          <Router>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </Router>
        </AuthContext.Provider>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <IconContext.Provider value={{ className: "react-icons" }}>
        <AuthContext.Provider value={{ user, apiUrl, entrepriseRoles }}>
          {!entrepriseRoles.isLoading ? (
            <RouterEcp entrepriseRoles={entrepriseRoles}/>
          ) : (
            <Loader />
          )}
        </AuthContext.Provider>
      </IconContext.Provider>
    </>
  );
}
