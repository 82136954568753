import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCheck,
  faFolderOpen,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

/**
 * 
 * @param {object} file - file or directory
 * @param {useQuery object} files - to refetch files
 * @param {string} path - path of the file
 * @param {function} handlePath - function to defini path in header of filemanager 
 */
export default function Directory({ file, files, path, handlePath }) {

  // toggle to display input
  const [isInput, setIsInput] = useState(false);

  //import context
  const { apiUrl } = useContext(AuthContext);

  // stock api error
  const [error, setError] = useState(null);

  // Change folder's name
  const updateFolder = (e) => {
    e.preventDefault();
    const input = document.querySelector(".input-updateFolder");
    const newFolderName = input.value;
    const folderName = input.defaultValue;

    const formData = new FormData();
    formData.append("current_folder", folderName);
    formData.append("new_folder_name", newFolderName);

    axios(`${apiUrl}files/renameFolder${path.length > 0 ? "/" + path : ""}`, {
      method: "put",
      withCredentials: true,
      data: formData,
    })
      .then((res) => {
        files.refetch();
        setIsInput(false);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const truncateFolderName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <>
      {!isInput ? (
        <div className="file-directory">
          <div onClick={() => handlePath(file)} className="file-directory_info">
            <FontAwesomeIcon icon={faFolderOpen} className="file-directory_icon" />
            <span>{truncateFolderName(file.name, 10)}</span>
          </div>
          <button
            onClick={() => setIsInput(!isInput)}
            className="button button-white file-directory_update"
          >
            <FontAwesomeIcon icon={faPen} />
          </button>
        </div>
      ) : (
        <div className="file-directory">
          <FontAwesomeIcon icon={faFolderOpen} className="file-directory_icon file-directory_new" />
          <form
            className="file-directory_form"
            onSubmit={(e) => updateFolder(e)}
          >
            <input
              defaultValue={file.name}
              className="input-updateFolder file-directory_form-input"
            />
            <div className="file-directory_form-button">
              <button type="submit" className="button button-new">
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                type="button"
                className="button button-delete"
                onClick={() => setIsInput(false)}
              >
                <FontAwesomeIcon icon={faCancel} />
              </button>
            </div>
            <span>{error && error.response.data}</span>
          </form>
        </div>
      )}
    </>
  );
}
