import React, { useEffect, useState } from "react";
import ChartsWebex from "../Charts/ChartsWebex";
import { useTranslation } from "react-i18next";
import { differenceInDays, format, parseISO } from 'date-fns';
import { StackedLineChart, StackedBarChart } from "@mui/icons-material";

export default function ChartsAllStatistics({ data, filters, date, handleGraphClick }) {
    const { t } = useTranslation();
    const [dataForChartDoughnutAnswer, setDataForChartDoughnutAnswer] = useState(null);
    const [allLabelsDoughnutAnswer, setAllLabelsDoughnutAnswer] = useState([]);
    const [dataForChartDoughnutDirection, setDataForChartDoughnutDirection] = useState(null);
    const [allLabelsDoughnutDirection, setAllLabelsDoughnutDirection] = useState([]);
    const [dataForChartCallTypeBar, setDataForChartCallTypeBar] = useState(null);
    const [allLabelsCallTypeBar, setAllLabelsCallTypeBar] = useState([]);
    const [dataForChartLocationBar, setDataForChartLocationBar] = useState(null);
    const [allLabelsLocationBar, setAllLabelsLocationBar] = useState([]);
    const [dataForChartTotalPerDays, setDataForChartTotalPerDays] = useState(null);
    const [allLabelsTotalPerDays, setAllLabelsTotalPerDays] = useState([]);
    const [dataForChartTotal, setDataForChartTotal] = useState(null);
    const [allLabelsTotal, setAllLabelsTotal] = useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState(t('Daily'));
    const [selectedTypeChart, setSelectedTypeChart] = useState('line');
    const [selectedTypeChartTotal, setSelectedTypeChartTotal] = useState('line');
    const [dataFiltered, setDataFiltered] = useState(data);

    useEffect(() => {
        if (data) {
            let dataFiltered = data;
            if (filters.length > 0) {
                filters.forEach(filter => {
                    dataFiltered = dataFiltered.filter(item => filter.values.includes(item[filter.column]));
                });
                setDataFiltered(dataFiltered);
            } else {
                setDataFiltered(dataFiltered);
            }
            handleDataForChartDoughnutAnswer(dataFiltered);
            handleDataForChartDoughnutDirection(dataFiltered);
            handleDataForChartCallTypeBar(dataFiltered);
            handleDataForChartLocationBar(dataFiltered);
            handleDataForChartTotalPerDays(dataFiltered, date);
            if(selectedTimeframe === t('Daily')) {
                handleDataForChartTotalDaily(dataFiltered);
            } else if(selectedTimeframe === t('Weekly')) {
                handleDataForChartTotalWeekly(dataFiltered);
            } else if(selectedTimeframe === t('Monthly')) {
                handleDataForChartTotalMonthly(dataFiltered);
            }
        }
    }, [data, filters]);

    const handleDataForChartDoughnutAnswer = (data) => {
        const groupedData = {};
        const allLabels = new Set();

        data.forEach(item => {
            const answerIndicator = item.answerindicator;
            const answerIndicatorTypeLabels = {
                'Yes': t('Oui'),
                'No': t('Non'),
                'Yes-PostRedirection': t('Redirection-d\'appel')
            };

            const answerIndicatorTypeDisplayName = answerIndicatorTypeLabels[answerIndicator] || answerIndicator;

            if (!groupedData[answerIndicatorTypeDisplayName]) groupedData[answerIndicatorTypeDisplayName] = 0;
            groupedData[answerIndicatorTypeDisplayName] += 1;
            allLabels.add(answerIndicatorTypeDisplayName);
        });

        setAllLabelsDoughnutAnswer(Array.from(allLabels));
        setDataForChartDoughnutAnswer(groupedData);
    };

    const handleDataForChartDoughnutDirection = (data) => {
        const groupedData = {};
        const allLabels = new Set();

        data.forEach(item => {
            const direction = item.direction;
            const directionTypeLabels = {
                'ORIGINATING': t('Sortant'),
                'TERMINATING': t('Entrant')
            };

            const directionTypeDisplayName = directionTypeLabels[direction] || direction;

            if (!groupedData[directionTypeDisplayName]) groupedData[directionTypeDisplayName] = 0;
            groupedData[directionTypeDisplayName] += 1;
            allLabels.add(directionTypeDisplayName);
        });

        setAllLabelsDoughnutDirection(Array.from(allLabels));
        setDataForChartDoughnutDirection(groupedData);
    };

    const handleDataForChartCallTypeBar = (data) => {
        const groupedData = {};
        const allLabels = new Set();
        const totals = {};

        data.forEach(item => {
            const callType = item.calltype;
            const userTypeLabels = {
                'SIP_ENTERPRISE': 'Interne',
                'SIP_NATIONAL': 'National',
                'SIP_MOBILE': 'Mobile',
                'UNKNOWN': 'Inconnu',
                'SIP_PREMIUM': 'Prenium',
                'SIP_INTERNATIONAL': 'International',
                'SIP_INBOUND': 'Entrant',
                'SIP_OPERATOR': 'Opérateur',
                'SIP_TOLLFREE': 'Sans frais',
                'SIP_MEETING': 'Réunion'
            };
            const userTypeDisplayName = userTypeLabels[callType] || callType;

            if (!groupedData[userTypeDisplayName]) groupedData[userTypeDisplayName] = {};
            if (!groupedData[userTypeDisplayName][userTypeDisplayName]) groupedData[userTypeDisplayName][userTypeDisplayName] = 0;
            groupedData[userTypeDisplayName][userTypeDisplayName] += 1;
            allLabels.add(userTypeDisplayName);
            if (!totals[userTypeDisplayName]) totals[userTypeDisplayName] = 0;
            totals[userTypeDisplayName] += 1;
        });

        const grandTotal = Object.values(totals).reduce((acc, curr) => acc + curr, 0);

        for (const callType in groupedData) {
            groupedData[callType]["Total"] = grandTotal;
        }

        setAllLabelsCallTypeBar(Array.from(allLabels));
        setDataForChartCallTypeBar(groupedData);
    };

    const handleDataForChartLocationBar = (data) => {
        const groupedData = {};
        const allLabels = new Set();
        const totals = {};

        data.forEach(item => {
            const location = item.location;

            const locationDisplayName = location;

            if (!groupedData[locationDisplayName]) groupedData[locationDisplayName] = {};
            if (!groupedData[locationDisplayName][locationDisplayName]) groupedData[locationDisplayName][locationDisplayName] = 0;
            groupedData[locationDisplayName][locationDisplayName] += 1;
            allLabels.add(locationDisplayName);
            if (!totals[locationDisplayName]) totals[locationDisplayName] = 0;
            totals[locationDisplayName] += 1;
        });

        const grandTotal = Object.values(totals).reduce((acc, curr) => acc + curr, 0);

        for (const callType in groupedData) {
            groupedData[callType]["Total"] = grandTotal;
        }

        setAllLabelsLocationBar(Array.from(allLabels));
        setDataForChartLocationBar(groupedData);
    };

    const handleDataForChartTotalPerDays = (data, dateRange) => {
        const groupedData = [];
        const allLabels = new Set();
       
        // Extraire les dates de début et de fin à partir du tableau dateRange
        const startDate = dateRange[0];
        const endDate = dateRange[1];

        // Calculer le nombre total de jours entre les deux dates
        const totalDays = differenceInDays(endDate, startDate) + 1;
        
        data.sort((a, b) => {
            const timeA = parseISO(a.starttime);
            const timeB = parseISO(b.starttime);
            return timeA.getTime() - timeB.getTime();
        });

        data.forEach(item => {
            const starttime = format(parseISO(item.starttime), 'HH:00');

            if (!groupedData[starttime]) {
                groupedData[starttime] = 0;
            }

            groupedData[starttime] += 1;
            allLabels.add(starttime);
        });

        // Calculer la moyenne par heure
        const averagedData = {};
        for (const hour in groupedData) {
            averagedData[hour] = Math.round(groupedData[hour] / totalDays);
        }

        const sortedLabels = Array.from(allLabels).sort((a, b) => a.localeCompare(b));

        setAllLabelsTotalPerDays(sortedLabels);
        setDataForChartTotalPerDays(averagedData);
    };

    const getWeekStart = (date) => {
        const day = date.getDay();

        const startOfWeek = new Date(date.setDate(date.getDate() - day));

        return startOfWeek;
    };

    const handleDataForChartTotalDaily = (data) => {
        const groupedData = {};
        const labels = new Set();

        data.forEach(item => {
            const day = new Date(parseISO(item.starttime)).getDate();
            const month = new Date(parseISO(item.starttime)).getMonth() + 1;
            const year = new Date(parseISO(item.starttime)).getFullYear();

            const dayFormatted = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

            if (!groupedData[dayFormatted]) {
                groupedData[dayFormatted] = 0;
            }

            groupedData[dayFormatted] += 1;
            labels.add(dayFormatted);
        });

        setAllLabelsTotal(Array.from(labels));
        setDataForChartTotal(groupedData);
    };

    const handleDataForChartTotalWeekly = (data) => {
        const groupedData = [];
        const labels = new Set();

        data.forEach(item => {
            const startDate = getWeekStart(parseISO(item.starttime));
            const weekKey = startDate.toLocaleDateString('fr-FR');

            if (!groupedData[weekKey]) {
                groupedData[weekKey] = 0;
            }

            groupedData[weekKey] += 1;
            labels.add(startDate.toLocaleDateString('fr-FR'));
        });

        setAllLabelsTotal(Array.from(labels));
        setDataForChartTotal(groupedData);
    };

    const handleDataForChartTotalMonthly = (data) => {
        const groupedData = [];
        const labels = new Set();

        data.forEach(item => {
            const year = new Date(parseISO(item.starttime)).getFullYear();
            const month = new Date(parseISO(item.starttime)).getMonth();

            const firstDay = new Date(year, month, 1);

            const firstDayFormatted = firstDay.toLocaleDateString('en-US');

            if (!groupedData[firstDayFormatted]) {
                groupedData[firstDayFormatted] = 0;
            }

            groupedData[firstDayFormatted] += 1;
            labels.add(firstDayFormatted);
        });

        setAllLabelsTotal(Array.from(labels));
        setDataForChartTotal(groupedData);
    };

    const handleTimeframeChange = (newTimeframe) => {
        setSelectedTimeframe(newTimeframe);
        switch (newTimeframe) {
            case t('Daily'):
                handleDataForChartTotalDaily(dataFiltered);
                break;
            case t('Weekly'):
                handleDataForChartTotalWeekly(dataFiltered);
                break;
            case t('Monthly'):
                handleDataForChartTotalMonthly(dataFiltered);
                break;
            default:
                console.error('Invalid timeframe selected');
        }
    };

    const handleTypeChartChange = (newTypeChart) => {
        setSelectedTypeChart(newTypeChart);
    };

    return (
        <div className="graphics-statistics-webex">
            <div className="graphics-statistics-webex-content graphics-statistics-webex-doughnut">
                <section className="page-chart-webex">
                    <h2 className="page-chart-webex-title">{t('Total Calls by Indicator answer')}</h2>
                    {!data[0].error ? (
                        <ChartsWebex
                            dataForChart={dataForChartDoughnutAnswer}
                            allLabels={allLabelsDoughnutAnswer}
                            typeOfChart={"doughnut"}
                            handleGraphClick={handleGraphClick}
                            labelTitle={'answerindicator'}
                        />
                    ) : (
                        <div>{t('No data available')}</div>
                    )}
                </section>
                <section className="page-chart-webex">
                    <h2 className="page-chart-webex-title">{t('Total Calls Inbound or Outbound')}</h2>
                    {!data[0].error ? (
                        <ChartsWebex
                            dataForChart={dataForChartDoughnutDirection}
                            allLabels={allLabelsDoughnutDirection}
                            typeOfChart={"doughnut"}
                            handleGraphClick={handleGraphClick}
                            labelTitle={'direction'}
                        />
                    ) : (
                        <div>{t('No data available')}</div>
                    )}
                </section>
            </div>
            <div className="graphics-statistics-webex-content graphics-statistics-webex-bar">
                <section className="page-chart-webex">
                    <h2 className="page-chart-webex-title">{t('Total calls by type')}</h2>
                    {!data[0].error ? (
                        <ChartsWebex
                            dataForChart={dataForChartCallTypeBar}
                            allLabels={allLabelsCallTypeBar}
                            typeOfChart={"bar"}
                            date={date}
                            handleGraphClick={handleGraphClick}
                            labelTitle={'calltype'}
                        />
                    ) : (
                        <div>{t('No data available')}</div>
                    )}
                </section>
                <section className="page-chart-webex">
                    <h2 className="page-chart-webex-title">{t('Total calls by location')}</h2>
                    {!data[0].error ? (
                        <ChartsWebex
                            dataForChart={dataForChartLocationBar}
                            allLabels={allLabelsLocationBar}
                            typeOfChart={"bar"}
                            date={date}
                            handleGraphClick={handleGraphClick}
                            labelTitle={'location'}
                        />
                    ) : (
                        <div>{t('No data available')}</div>
                    )}
                </section>
            </div>
            <div className="graphics-statistics-webex-content graphics-statistics-webex-bar">
                <section className="page-chart-webex">
                    <h2 className="page-chart-webex-title">{t('Total calls')}</h2>
                    <div className="page-chart-webex-total-content-buttons">
                        <div className="page-chart-webex-total-buttons">
                            <button className={`${selectedTimeframe === t('Daily') ? 'button-target-data' : ''}`} onClick={() => handleTimeframeChange(t('Daily'))}>{t('Daily')}</button>
                            <button className={`${selectedTimeframe === t('Weekly') ? 'button-target-data' : ''}`} onClick={() => handleTimeframeChange(t('Weekly'))}>{t('Weekly')}</button>
                            <button className={`${selectedTimeframe === t('Monthly') ? 'button-target-data' : ''}`} onClick={() => handleTimeframeChange(t('Monthly'))}>{t('Monthly')}</button>
                        </div>
                        <div className="page-chart-webex-total-buttons">
                            <button className={`${selectedTypeChartTotal === 'line' ? 'button-target-data' : ''}`} onClick={() => setSelectedTypeChartTotal('line')}><StackedLineChart /></button>
                            <button className={`${selectedTypeChartTotal === 'line-bar' ? 'button-target-data' : ''}`} onClick={() => setSelectedTypeChartTotal('line-bar')}><StackedBarChart /></button>
                        </div>
                    </div>
                    {!data[0].error ? (
                        <ChartsWebex
                            dataForChart={dataForChartTotal}
                            allLabels={allLabelsTotal}
                            typeOfChart={selectedTypeChartTotal}
                        />
                    ) : (
                        <div>{t('No data available')}</div>
                    )}
                </section>
                <section className="page-chart-webex">
                    <h2 className="page-chart-webex-title">{t('Average distribution of calls per hour')}</h2>
                    <div className="page-chart-webex-total-buttons">
                        <button className={`${selectedTypeChart === 'line' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('line')}><StackedLineChart /></button>
                        <button className={`${selectedTypeChart === 'line-bar' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('line-bar')}><StackedBarChart /></button>
                    </div>
                    {!data[0].error ? (
                        <ChartsWebex
                            dataForChart={dataForChartTotalPerDays}
                            allLabels={allLabelsTotalPerDays}
                            typeOfChart={selectedTypeChart}
                            labelTitle={'Average_Hour'}
                        />
                    ) : (
                        <div>{t('No data available')}</div>
                    )}
                </section>
            </div>
        </div>
    )
}